import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/joy";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import styles from "./FavoriteButton.module.css";
import { toggleConversationFavorite, toggleDocumentFavorite } from "../../api/api";
import { useAuth } from "@clerk/clerk-react";

interface FavoriteButtonProps {
    id: string;
    type: "conversation" | "document";
    initialFavoriteState: boolean;
    size?: "sm" | "md" | "lg";
    className?: string;
    onFavoriteChange?: (isFavorite: boolean) => void;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = ({ id, type, initialFavoriteState, size = "sm", className = "", onFavoriteChange }) => {
    const [isFavorite, setIsFavorite] = useState(initialFavoriteState);
    const { getToken } = useAuth();

    useEffect(() => {
        setIsFavorite(initialFavoriteState);
    }, [initialFavoriteState]);

    const handleClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        try {
            const token = await getToken();

            const response = type === "conversation" ? await toggleConversationFavorite(id, token) : await toggleDocumentFavorite(id, token);

            if (!response.ok) {
                throw new Error(`Failed to update favorite status for ${type}`);
            }

            const newState = !isFavorite;
            setIsFavorite(newState);
            onFavoriteChange?.(newState);
        } catch (error) {
            console.error(`Error updating favorite status for ${type}:`, error);
        }
    };

    return (
        <IconButton
            variant="plain"
            color={isFavorite ? "warning" : "neutral"}
            onClick={handleClick}
            size={size}
            className={`${styles.favoriteButton} ${className}`}
        >
            {isFavorite ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
    );
};
