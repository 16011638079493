import React from "react";
import { Modal, Button, Stack, Typography } from "@mui/joy";
import styles from "./SourceActionPopup.module.css";
import { SummaryViewer } from "../SummaryViewer/SummaryViewer";
import { DocumentResult } from "../../api";
import { ChatPDFButton } from "../ChatPDFButton/ChatPDFButton";
import { ViewOriginalButton } from "../ViewOriginalButton/ViewOriginalButton";
import { SaveDocButton } from "../SaveDocButton";

interface SourceActionPopupProps {
    open: boolean;
    onClose: () => void;
    resultDocument?: DocumentResult;
    onMenuAction?: () => void;
}

export const SourceActionPopup: React.FC<SourceActionPopupProps> = ({ open, onClose, resultDocument, onMenuAction }) => {
    if (!resultDocument) {
        return null;
    }

    const canChatWithPDF = resultDocument?.url?.toLowerCase?.()?.endsWith(".pdf") ?? false;
    const hasValidId = !!(resultDocument.doc_id || resultDocument.id);

    return (
        <Modal open={open} onClose={onClose} className={styles.modal}>
            <Stack spacing={1} className={styles.buttonContainer}>
                <Typography level="title-md" className={styles.sourceTitle} fontWeight="bold">
                    {resultDocument.name || "Untitled Document"}
                </Typography>

                {canChatWithPDF && resultDocument && (
                    <div className={styles.actionButton}>
                        <ChatPDFButton variantOverride={"soft"} document={resultDocument} onComplete={onMenuAction || onClose} isMenuButton={true} />
                    </div>
                )}

                {resultDocument.summary1 && (
                    <div className={styles.actionButton}>
                        <SummaryViewer document={resultDocument} variantOverride="soft" isMenuButton={true} />
                    </div>
                )}

                {resultDocument.url && <ViewOriginalButton document={resultDocument} variantOverride="soft" onComplete={onClose} sx={{ width: "100%" }} />}

                {hasValidId && <SaveDocButton document={resultDocument} isMenuButton={true} />}

                <Button variant="soft" color="neutral" onClick={onClose} className={styles.actionButton}>
                    Close Menu
                </Button>
            </Stack>
        </Modal>
    );
};
