import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Dropdown, ListDivider, Menu, MenuButton, MenuItem, Typography, Button, Modal, Stack } from "@mui/joy";
import { useContext, useState } from "react";
import { DocumentResult, saveDocument, toggleDocumentFavorite, createProject } from "../../api";
import { useAuth } from "@clerk/clerk-react";
import { Project } from "../ProjectsComponents/types";
import { useSnackbar } from "../../contexts/useSnackbar";
import { UserContext } from "../../contexts/UserContext";
import AddIcon from "@mui/icons-material/Add";
import CreateProjectModal from "../ProjectsComponents/CreateProjectModal";
import { useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import FolderOutlineIcon from "@mui/icons-material/FolderOutlined";
import { useTheme } from "@mui/joy/styles";

interface SaveDocButtonProps {
    document: DocumentResult;
    placement?:
        | "right-start"
        | "right"
        | "right-end"
        | "left-start"
        | "left"
        | "left-end"
        | "top-start"
        | "top"
        | "top-end"
        | "bottom-start"
        | "bottom"
        | "bottom-end"
        | "auto"
        | undefined;
    isMenuButton?: boolean;
    variantOverride?: "plain" | "soft" | "outlined";
    isMobileIcon?: boolean;
    size?: "sm" | "md" | "lg";
}

export const SaveDocButton = ({
    document,
    placement = "auto",
    isMenuButton = false,
    variantOverride,
    isMobileIcon = false,
    size = "sm"
}: SaveDocButtonProps): JSX.Element | null => {
    const [open, setOpen] = useState<boolean>(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { getToken } = useAuth();
    const { showSnackbar } = useSnackbar();
    const { user, updateUser } = useContext(UserContext);
    const projects: Project[] | undefined = user?.projects;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // Safely get the document ID, return null if no valid ID is found
    const getDocumentId = () => {
        if (!document) return null;
        return document.doc_id || document.id;
    };

    const documentId = getDocumentId();

    // If we don't have a valid document ID, don't render the button
    if (!documentId) {
        return null;
    }

    const handleMenuOpen = () => {
        setOpen(!open);
    };

    const handleSave = async (option: string) => {
        try {
            if (option === "Starred") {
                toggleDocumentFavorite(documentId, await getToken());
            } else {
                saveDocument({ doc_id: documentId, project_id: option }, await getToken());
            }
            showSnackbar({ message: "Document saved successfully", variant: "success" });
        } catch (error) {
            showSnackbar({ message: "Failed to save document", variant: "danger" });
            console.error("Failed to save document", error);
        }
    };

    const createNewProject = async (name: string, description: string) => {
        try {
            const token = await getToken();
            const response = await createProject(name, description, token);

            // Save the document to the newly created project
            await saveDocument(
                {
                    doc_id: documentId,
                    project_id: response.id
                },
                token
            );

            await updateUser(); // Update user context to get new project list
            setCreateModalOpen(false);
            showSnackbar({ message: "Project created and document saved successfully", variant: "success" });
        } catch (error) {
            showSnackbar({ message: "Failed to create project", variant: "danger" });
            console.error("Failed to create project", error);
        }
    };

    const truncateTitle = (title: string, limit: number = 25) => {
        return title.length > limit ? `${title.substring(0, limit)}...` : title;
    };

    const handleCreateProjectClick = () => {
        setCreateModalOpen(true);
        // Only close the save menu if we're not in the mobile/popup view
        if (!isMobile) {
            setOpen(false);
        }
    };

    const MobileModalContent = () => (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography level="title-md">Save to..</Typography>
                <Button variant="plain" color="neutral" onClick={() => setOpen(false)} startDecorator={<CloseIcon />}>
                    Close
                </Button>
            </Stack>

            <Button
                fullWidth
                variant="soft"
                onClick={() => handleSave("Starred")}
                startDecorator={
                    <StarIcon
                        sx={{
                            color: "#FFB400 !important",
                            fill: "#FFB400 !important"
                        }}
                    />
                }
                sx={{
                    justifyContent: "flex-start"
                }}
            >
                Starred Documents
            </Button>

            <Typography level="title-sm" sx={{ fontWeight: "bold" }}>
                Projects
            </Typography>

            <Button
                fullWidth
                variant="soft"
                onClick={handleCreateProjectClick}
                startDecorator={<AddIcon />}
                sx={{
                    color: "primary.main",
                    "&:hover": { bgcolor: "primary.softHover" },
                    justifyContent: "flex-start"
                }}
            >
                New Project
            </Button>

            <Stack
                spacing={1}
                sx={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    pl: 1,
                    "&::-webkit-scrollbar": {
                        width: "8px"
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#f1f1f1",
                        borderRadius: "4px"
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#888",
                        borderRadius: "4px"
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555"
                    }
                }}
            >
                {projects?.map(project => (
                    <Button
                        key={project.id}
                        fullWidth
                        variant="soft"
                        onClick={() => handleSave(project.id)}
                        startDecorator={<FolderOutlineIcon sx={{ color: "neutral.500" }} />}
                        sx={{
                            justifyContent: "flex-start",
                            ml: 3
                        }}
                    >
                        {truncateTitle(project.name)}
                    </Button>
                ))}
            </Stack>
        </Stack>
    );

    if (isMobile || isMenuButton) {
        return (
            <>
                <Button
                    fullWidth={isMenuButton}
                    variant={variantOverride || "soft"}
                    startDecorator={<BookmarkBorderOutlinedIcon />}
                    onClick={() => setOpen(true)}
                    size={size}
                    sx={{
                        justifyContent: "center",
                        "& .MuiButton-startDecorator": {
                            margin: isMobileIcon ? 0 : undefined
                        },
                        gap: 1
                    }}
                >
                    {!isMobileIcon && "Save"}
                </Button>

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={
                        isMobile
                            ? {
                                  display: "flex",
                                  alignItems: "flex-end",
                                  "& .MuiModal-backdrop": {
                                      backgroundColor: "rgba(0, 0, 0, 0.8)"
                                  }
                              }
                            : undefined
                    }
                >
                    <Stack
                        spacing={2}
                        sx={{
                            width: "100%",
                            maxHeight: "90vh",
                            bgcolor: "#fff",
                            borderTopLeftRadius: isMobile ? "12px" : undefined,
                            borderTopRightRadius: isMobile ? "12px" : undefined,
                            p: 2,
                            overflowY: "auto",
                            boxShadow: "sm"
                        }}
                    >
                        <MobileModalContent />
                    </Stack>
                </Modal>

                <CreateProjectModal open={createModalOpen} onClose={() => setCreateModalOpen(false)} onCreateProject={createNewProject} />
            </>
        );
    }

    return (
        <>
            <Dropdown
                onClick={handleMenuOpen}
                sx={{
                    m: isMenuButton ? 0 : 2,
                    width: isMenuButton ? "100%" : "auto",
                    justifyContent: isMenuButton ? "center" : "left"
                }}
            >
                <MenuButton
                    startDecorator={<BookmarkBorderOutlinedIcon />}
                    color="primary"
                    variant={isMenuButton ? "soft" : "plain"}
                    size="md"
                    sx={{
                        justifyContent: isMenuButton ? "center" : "left",
                        width: isMenuButton ? "100%" : "auto"
                    }}
                >
                    {(!isMobile || isMenuButton) && "Save"}
                </MenuButton>
                <Menu placement={placement} sx={{ maxHeight: "40%" }}>
                    <Typography level="body-sm" sx={{ fontWeight: "bold", p: 1 }}>
                        Save to..
                    </Typography>
                    <MenuItem onClick={() => handleSave("Starred")}>
                        <Typography level="title-sm">Starred Documents</Typography>
                    </MenuItem>

                    <ListDivider />

                    <Typography level="body-sm" sx={{ fontWeight: "bold", p: 1 }}>
                        Projects
                    </Typography>

                    <MenuItem
                        onClick={() => {
                            setCreateModalOpen(true);
                            setOpen(false);
                        }}
                        sx={{
                            color: "primary.main",
                            "&:hover": { bgcolor: "primary.softHover" }
                        }}
                    >
                        <AddIcon sx={{ mr: 1 }} />
                        <Typography level="title-sm">New Project</Typography>
                    </MenuItem>

                    {projects?.map(project => (
                        <MenuItem key={project.id} onClick={() => handleSave(project.id)}>
                            <Typography level="title-sm" title={project.name}>
                                {truncateTitle(project.name)}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Dropdown>

            <CreateProjectModal open={createModalOpen} onClose={() => setCreateModalOpen(false)} onCreateProject={createNewProject} />
        </>
    );
};
