import { useContext, useState } from "react";
import styles from "./QuestionInput.module.css";
import { Box, Textarea, Tooltip } from "@mui/joy";
import { useAuth, useClerk } from "@clerk/clerk-react";
import { UserContext } from "../../contexts/UserContext";
import { CheckoutModal } from "../Checkout";
import { ChatTypeSelector } from "../ChatTypeSelector";
import IconButton from "@mui/joy/IconButton";
import { ChevronRight, Search } from "@mui/icons-material";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";

interface Props {
    onSend: (question: string) => void;
    placeholder?: string;
    clearOnSend?: boolean;
    showChatSelect?: boolean;
    size?: "sm" | "md" | "lg";
    allowEmpty?: boolean;
    initialQuestion?: string;
    unrestricted?: boolean;
    forceShowChatSelect?: boolean;
}

export const QuestionInput = ({
    onSend,
    placeholder,
    clearOnSend,
    showChatSelect,
    size,
    allowEmpty,
    initialQuestion,
    unrestricted = false,
    forceShowChatSelect = false
}: Props): JSX.Element => {
    const [openCheckout, setOpenCheckout] = useState<boolean>(false);
    const [question, setQuestion] = useState<string>(initialQuestion || "");
    const { isSignedIn } = useAuth();
    const { redirectToSignIn } = useClerk();
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const location = window.location.pathname;
    const [searchParams] = useSearchParams();
    const [selectedChatType, setSelectedChatType] = useState<string>(location.includes("/casenotes") || location.includes("/find") ? "find" : "chat");
    const MAX_CHARS = 5000;
    const { countryCode } = useParams();

    const isChatDisabled = () => {
        if (unrestricted) return !isSignedIn;
        return !isSignedIn || ((user?.searches_left || 0) <= 0 && selectedChatType === "chat");
    };

    const isInputDisabled = () => {
        return !isSignedIn;
    };

    const handleSubmit = (questionText: string): void => {
        console.log("QuestionInput - Submit triggered with:", {
            location,
            selectedChatType,
            questionText
        });

        if (isChatDisabled() || (!questionText.trim() && !allowEmpty)) {
            return;
        }

        // If on search page and chat is selected, navigate to chat page
        if (selectedChatType === "chat" && (location.includes("/casenotes") || location.includes("/find"))) {
            const params = new URLSearchParams();
            params.set("search", questionText);
            params.set("newChat", "true");

            // Handle multiple filter values
            const filterParams = ["jurisdiction", "doc_type", "court"];
            filterParams.forEach(filter => {
                // Get all values for this filter
                const filterValues = searchParams.getAll(filter);
                if (filterValues.length > 0) {
                    // Handle both comma-separated values and multiple parameters
                    filterValues.forEach(value => {
                        value.split(",").forEach(v => {
                            if (v.trim()) {
                                params.append(filter + "[]", v.trim());
                            }
                        });
                    });
                }
            });
            navigate(`/${countryCode}/?${params.toString()}`, { replace: true });
            return;
        }

        // Otherwise, proceed with normal chat/search
        onSend(questionText);
        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>): void => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            handleSubmit(question);
        }
    };

    const handleSearchIconClick = (): void => {
        console.log("Search icon clicked with chat type:", selectedChatType);
        handleSubmit(question);
    };

    const onQuestionChange = (value: string) => {
        if (!value) {
            setQuestion("");
        } else if (value.length <= MAX_CHARS) {
            setQuestion(value);
        }
    };

    // Determine which icon to show based on selected chat type
    const getIcon = () => {
        if (selectedChatType === "find") {
            return <Search />;
        }
        return <ChevronRight />;
    };

    return !isChatDisabled() ? (
        <Box className={styles.questionArea} data-tour="questionBox">
            {(showChatSelect || forceShowChatSelect) && (
                <ChatTypeSelector
                    onChatTypeChange={type => {
                        setSelectedChatType(type);
                        // Only update URL params on chat page
                        if (location === `/${countryCode}`) {
                            const params = new URLSearchParams(searchParams);
                            params.set("chatType", type);
                            navigate({
                                pathname: `${location}`,
                                search: params.toString()
                            });
                        }
                    }}
                />
            )}
            <Box className={styles.questionInputContainer}>
                <Textarea
                    maxRows={6}
                    size={size || "lg"}
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    onChange={event => onQuestionChange(event.target.value)}
                    onKeyDown={onEnterPress}
                    value={question}
                    disabled={isInputDisabled()}
                    endDecorator={
                        <Box className={styles.endDecorator}>
                            <IconButton color="primary" variant="plain" onClick={handleSearchIconClick}>
                                {getIcon()}
                            </IconButton>
                        </Box>
                    }
                />
            </Box>
        </Box>
    ) : (
        <Box className={styles.questionArea}>
            {(showChatSelect || forceShowChatSelect) && (
                <ChatTypeSelector
                    onChatTypeChange={type => {
                        setSelectedChatType(type);
                    }}
                />
            )}
            <Tooltip title={!isSignedIn ? "Please sign in" : "You are out of searches for this month."} followCursor={true}>
                <Box
                    className={styles.questionInputContainer}
                    onClick={() => {
                        if (!isSignedIn) redirectToSignIn();
                        if (isSignedIn && user?.searches_left === 0 && selectedChatType === "chat") setOpenCheckout(true);
                    }}
                >
                    <Textarea
                        maxRows={6}
                        size={size || "lg"}
                        className={styles.questionInputTextArea}
                        placeholder={placeholder}
                        onChange={event => onQuestionChange(event.target.value)}
                        onKeyDown={onEnterPress}
                        value={question}
                        disabled={isInputDisabled()}
                    />
                </Box>
            </Tooltip>
            <CheckoutModal isOpen={openCheckout} toggleModal={() => setOpenCheckout(!openCheckout)} userEmail={user?.email || ""} />
        </Box>
    );
};
