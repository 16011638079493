import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, Button } from "@mui/joy";
import { uploadFile } from "../../../api";
import { useAuth } from "@clerk/clerk-react";
import { FileInfo } from "../types";
import So_empty_Robot from "../../../assets/So_empty_Robot.webp";
import { useMediaQuery } from "@mui/material";

interface DropZoneProps {
    onFileUpload: (fileInfo: FileInfo) => void;
    projectId: string | undefined;
    existingFiles: FileInfo[];
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

const DropZone: React.FC<DropZoneProps> = ({ onFileUpload, projectId, existingFiles }) => {
    const [loading, setLoading] = useState(false);
    const { getToken } = useAuth();
    const isMobile = useMediaQuery("(max-width:844px)");

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0 && projectId) {
                setLoading(true);
                try {
                    const token = await getToken({ template: "Standard" });
                    for (const file of acceptedFiles) {
                        const response = await uploadFile(file, projectId, token);
                        onFileUpload(response.file_info);
                    }
                } catch (error) {
                    console.error("Error uploading files:", error);
                } finally {
                    setLoading(false);
                }
            }
        },
        [onFileUpload, projectId]
    );

    const fileValidator = useCallback((file: File) => {
        if (file.size > MAX_FILE_SIZE) {
            return {
                code: "file-too-large",
                message: `File is larger than ${MAX_FILE_SIZE / 1024 / 1024}MB`
            };
        }
        return null;
    }, []);

    const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useDropzone({
        onDrop,
        accept: {
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/msword": [".doc"]
        },
        validator: fileValidator,
        maxSize: MAX_FILE_SIZE
    });

    const fileRejectionItems = useMemo(() => {
        return fileRejections.map(({ file, errors }) => (
            <li key={file.name}>
                {file.name} - {errors.map(e => e.message).join(", ")}
            </li>
        ));
    }, [fileRejections]);

    if (isMobile) {
        return (
            <Box sx={{ width: "100%" }}>
                <Box sx={{ textAlign: "center" }}>
                    <Button loading={loading} onClick={open} fullWidth variant="soft">
                        Upload
                    </Button>
                    <Typography
                        level="body-sm"
                        sx={{
                            mt: 1,
                            color: "text.secondary",
                            fontSize: "0.75rem"
                        }}
                    >
                        Allowed: PDF, DOCX, DOC (Max: 10MB)
                    </Typography>
                    <input {...getInputProps()} />
                </Box>
                {fileRejections.length > 0 && (
                    <Box sx={{ mt: 2, color: "error.main" }}>
                        <Typography>File upload errors:</Typography>
                        <ul>{fileRejectionItems}</ul>
                    </Box>
                )}
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%", mb: 2 }}>
            <div {...getRootProps()} className="dropzone" onClick={e => e.preventDefault()} style={{ width: "100%" }}>
                <input {...getInputProps()} />
                {existingFiles.length === 0 && (
                    <div className="empty-folder">
                        <img src={So_empty_Robot} alt="Empty folder" />
                        <Typography className="empty-folder-text">Start by uploading your files</Typography>
                    </div>
                )}
                <Typography>{isDragActive ? "Drop the files here ..." : "Drag 'n' drop some files here, or click to select files"}</Typography>
                <Typography sx={{ mt: 1, fontSize: "0.875rem", color: "text.secondary" }}>Allowed file types: PDF, DOCX, DOC (Max size: 10MB)</Typography>
                <Button loading={loading} onClick={open} sx={{ mt: 2 }}>
                    Select Files
                </Button>
            </div>
            {fileRejections.length > 0 && (
                <Box sx={{ mt: 2, color: "error.main" }}>
                    <Typography>File upload errors:</Typography>
                    <ul>{fileRejectionItems}</ul>
                </Box>
            )}
        </Box>
    );
};

export default DropZone;
