import mixpanel from "mixpanel-browser";
import { DocumentResult } from "../api";

export function recordEvent(eventName: string, eventProperties: object): void {
    mixpanel.track(eventName, eventProperties);
}

export type DateOptions =
    | {
          year: "numeric" | "2-digit" | undefined;
          month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
          day: "numeric" | "2-digit" | undefined;
      }
    | undefined;

export function getDate(doc: DocumentResult, options: DateOptions = undefined): string {
    const date = doc.decision_date || doc.date_of_decision || doc.date;
    if (!date) {
        return "";
    }

    return new Date(date).toLocaleDateString("en-AU", options);
}

export const animations: string[] = [];

export const removeDuplicates = <T>(arrayWithDuplicates: T[], keyToFilter: keyof T): T[] => {
    const uniqueIds = new Set<T[keyof T]>();
    const uniqueArray = arrayWithDuplicates.filter(item => {
        if (!uniqueIds.has(item[keyToFilter])) {
            uniqueIds.add(item[keyToFilter]);
            return true;
        }
        return false;
    });
    return uniqueArray;
};

export const isPDF = (url: string | undefined): boolean => {
    if (!url) {
        return false;
    }
    return url?.toLowerCase().endsWith("pdf") || url?.toLowerCase().includes("/pdf/");
};
