import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { getUser } from "../api";
import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";
import { CountryContext } from "./CountryContext";
import { Project } from "../components/ProjectsComponents/types";
import { useSnackbar } from "./useSnackbar";
import BillingIssueModal from "../components/BillingIssueModal/BillingIssueModal";

interface Props {
    children: React.ReactNode;
}

interface Plan {
    created: string;
    id: number;
    name: string;
    searches: number;
    stripe_id: string;
    updated: string;
    subscription_expiration: string | null;
    subscription_start: string;
}

interface Referral {
    referred: {
        email: string;
    };
    bonusSearches: number;
    expiry: string;
}

interface User {
    email: string;
    first: string;
    id: string;
    last: string;
    plan: Plan;
    searches_left: number;
    reset_date: string;
    referral_code: string;
    referrals: Referral[];
    projects: Project[];
    default_country: string;
    overdue?: boolean;
    overdue_days?: number;
}

export interface UserContextType {
    user: User | null;
    updateUser: () => void;
}

export const UserContext = React.createContext<UserContextType>({
    user: null,
    updateUser: () => {}
});

export const UserProvider: React.FC<Props> = ({ children }) => {
    const { getToken } = useAuth();
    const { setCountryByName, country } = useContext(CountryContext);
    const { showSnackbar } = useSnackbar();
    const [isBillingIssueModalOpen, setBillingIssueModalOpen] = useState(false);
    const DAYS_TO_BLOCK_USER = 6;

    const [userDetails, setUserDetails] = useState<User | null>(null);
    const updateUser = async () => {
        const inviteCode = Cookies.get("invite_code");
        const resp = await getUser(await getToken({ template: "Standard" }), inviteCode, country?.name);
        const parsedResp = await resp.json();

        setUserDetails(parsedResp);
        mixpanel.identify(parsedResp.id);
        setCountryByName(parsedResp.default_country);

        if (parsedResp.overdue && parsedResp.overdue_days < DAYS_TO_BLOCK_USER) {
            showSnackbar({
                message: "Your account is overdue. To avoid losing access please update your payment information.",
                variant: "danger"
            });
        }
        if (parsedResp.overdue && parsedResp.overdue_days >= DAYS_TO_BLOCK_USER) {
            setBillingIssueModalOpen(true);
        }
    };

    useEffect(() => {
        updateUser();
    }, []);

    return (
        <UserContext.Provider value={{ user: userDetails, updateUser }}>
            {children}
            <BillingIssueModal
                open={isBillingIssueModalOpen}
                url={`https://billing.stripe.com/p/login/3csg13blV6a3eswcMM?prefilled_email=${encodeURIComponent(userDetails?.email || "")}`}
            />
        </UserContext.Provider>
    );
};
