import React, { useState, useEffect } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useAuth } from "@clerk/clerk-react";
import { completeTour } from "../../api/api";

const desktopSteps: Step[] = [
    {
        target: "#root",
        content: (
            <div
                style={{
                    textAlign: "left",
                    // width: window.matchMedia("(max-width: 850px)").matches ? "92vw" : "750px",
                    maxWidth: window.matchMedia("(max-width: 900px)").matches ? "400px" : "1000px"
                }}
            >
                <h2
                    style={{
                        fontSize: window.matchMedia("(max-width: 900px)").matches ? "18px" : "32px",
                        marginBottom: "20px",
                        color: "#1976d2"
                    }}
                >
                    Welcome to Courtaid.ai! 🎉
                </h2>

                <p
                    style={{
                        fontSize: window.matchMedia("(max-width: 900px)").matches ? "14px" : "22px",
                        marginBottom: "20px",
                        lineHeight: "1.6"
                    }}
                >
                    We're on a mission to simplify legal research. Our platform provides up-to-date court rulings and legislation, making it easy to navigate
                    with advanced AI-powered search and chat capabilities.
                </p>

                <h3
                    style={{
                        fontSize: window.matchMedia("(max-width: 900px)").matches ? "16px" : "26px",
                        marginBottom: "15px",
                        color: "#1976d2"
                    }}
                >
                    ✨ Key Features:
                </h3>

                <ul
                    style={{
                        fontSize: window.matchMedia("(max-width: 900px)").matches ? "14px" : "22px",
                        lineHeight: "1.7",
                        marginLeft: "20px",
                        marginBottom: "20px"
                    }}
                >
                    <li>AI-powered legal research assistant chat</li>
                    <li>Automatic judgment summaries</li>
                    <li>Smart legislation chat</li>
                    <li>Advanced search and filtering</li>
                    <li>Weekly content updates</li>
                    <li>Create projects to combine your data with court data</li>
                </ul>

                <p
                    style={{
                        fontSize: window.matchMedia("(max-width: 900px)").matches ? "14px" : "22px",
                        marginTop: "20px",
                        fontWeight: 500,
                        color: "#1976d2"
                    }}
                >
                    Let's show you around!
                </p>
            </div>
        ),
        placement: "center",
        disableBeacon: true,
        styles: {
            options: {
                overlayColor: "rgba(0, 0, 0, 0.85)"
            },
            tooltip: {
                padding: window.matchMedia("(max-width: 900px)").matches ? "16px" : "35px",
                width: window.matchMedia("(max-width: 900px)").matches ? "92vw" : "1000px",
                maxWidth: window.matchMedia("(max-width: 900px)").matches ? "400px" : "1000px",
                fontSize: window.matchMedia("(max-width: 900px)").matches ? "14px" : "22px",
                lineHeight: "1.6"
            }
        }
    },
    {
        target: '[data-tour="filters"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                Start here! Narrow down your dataset by selecting jurisdictions, document types, and courts.
            </div>
        ),
        placement: "bottom",
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: '[data-tour="chat-type-selector"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                Choose between AI Chat and Search modes. Chat uses your monthly queries, to analyse documents, while Search is Unlimited!!
            </div>
        ),
        placement: "bottom",
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: '[data-tour="questionBox"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                Ask any legal question here - our AI will analyse the selected documents and provide detailed answers. The more specific your question, the more
                accurate our response!
            </div>
        ),
        placement: "top",
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: '[data-tour="new-chat"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                <p style={{ marginBottom: "15px" }}>Start a new conversation here when you want to begin fresh.</p>
                <p style={{ fontStyle: "italic", color: "#1976d2" }}>Hot tip: A new conversation for a new topic can improve results!</p>
            </div>
        ),
        placement: "right"
    },
    {
        target: '[data-tour="search"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                <p style={{ marginBottom: "15px" }}>Search and analyse case law with our advanced search features.</p>
            </div>
        ),
        placement: "right"
    },
    {
        target: '[data-tour="projects"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                <p style={{ marginBottom: "15px" }}>
                    Create your own workspace here! Build projects with hand-selected documents or combine our library with your own materials.
                </p>
                <p style={{ fontStyle: "italic", color: "#1976d2" }}>Hot tip: Projects are perfect for organizing research by topic or case!</p>
            </div>
        ),
        placement: "right"
    },
    {
        target: '[data-tour="history"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                <p style={{ marginBottom: "15px" }}>Your search history will show here.</p>
                <p style={{ fontStyle: "italic", color: "#1976d2" }}>Hot tip: you can favorite your top searches for quick access!</p>
            </div>
        ),
        placement: "right"
    },
    {
        target: '[data-tour="documents"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                <p style={{ marginBottom: "15px" }}>Your recently viewed documents will appear here.</p>
                <p style={{ fontStyle: "italic", color: "#1976d2" }}>Hot tip: star your favorite ones for easy access!</p>
            </div>
        ),
        placement: "right"
    },
    {
        target: '[data-tour="subscription"]',
        content: (
            <div style={{ fontSize: "22px", lineHeight: "1.6" }}>
                View your plan details and remaining queries here. Need more queries? You can upgrade your plan or share to earn additional queries!
            </div>
        ),
        placement: "top"
    }
];

const mobileSteps: Step[] = [
    ...desktopSteps.slice(0, 4),
    {
        target: '[data-testid="menu-button"]',
        content: (
            <div style={{ textAlign: "left" }}>
                <h3 style={{ marginBottom: "12px", fontSize: "18px" }}>Menu</h3>
                <p style={{ marginBottom: "10px", fontSize: "16px" }}>Click the menu icon to access:</p>
                <ul
                    style={{
                        marginLeft: "20px",
                        fontSize: "16px",
                        lineHeight: "1.5",
                        listStylePosition: "outside",
                        textAlign: "left"
                    }}
                >
                    <li>Search functionality</li>
                    <li>Chat history</li>
                    <li>Document management</li>
                    <li>Account settings</li>
                </ul>
            </div>
        ),
        placement: "bottom",
        styles: {
            options: {
                width: "300px"
            },
            tooltip: {
                textAlign: "left"
            }
        }
    }
];

export const Tour = (): JSX.Element => {
    const [run, setRun] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const { getToken, isLoaded, isSignedIn } = useAuth();

    useEffect(() => {
        // Only check for auto-start if tour hasn't been manually triggered
        if (run) return;

        const checkAndStartTour = async () => {
            if (!isLoaded || !isSignedIn) return;

            try {
                const token = await getToken({ template: "Standard" });
                const response = await fetch("/user", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const userData = await response.json();
                if (userData.tour === null) {
                    setRun(true);
                }
            } catch (error) {
                console.error("Error checking tour status:", error);
            }
        };

        checkAndStartTour();
    }, [getToken, isLoaded, isSignedIn, run]);

    // Listen for manual tour starts
    useEffect(() => {
        const handleTourStart = () => {
            setRun(true);
            setStepIndex(0);
        };

        window.addEventListener("startTour", handleTourStart);
        return () => window.removeEventListener("startTour", handleTourStart);
    }, []);

    const handleJoyrideCallback = async (data: CallBackProps): Promise<void> => {
        const { status, type, index } = data;

        // @ts-expect-error I'm not sure
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            try {
                const token = await getToken({ template: "Standard" });
                await completeTour(token);
                setRun(false);
                setStepIndex(0);
            } catch (error) {
                console.error("Failed to mark tour as complete:", error);
            }
        } else if (type === "step:after") {
            setStepIndex(index + 1);
        }
    };

    // Use CSS media query to determine which steps to show
    const tourSteps = window.matchMedia("(max-width: 900px)").matches ? mobileSteps : desktopSteps;

    return (
        <Joyride
            callback={handleJoyrideCallback}
            continuous={true}
            hideCloseButton={true}
            run={run}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            stepIndex={stepIndex}
            steps={tourSteps}
            styles={{
                options: {
                    primaryColor: "#1976d2",
                    zIndex: 1000
                }
            }}
            disableOverlayClose={true}
            spotlightClicks={true}
        />
    );
};
