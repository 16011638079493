import React from "react";
import { ChatView } from "../../components/ChatView";
import Project from "../../components/ProjectsComponents/FolderContent";
import { ChatAppResponseOrError } from "../../api";

interface ProjectChatViewProps {
    onClose: () => void;
    handleSendQuestion: (question: string) => void;
    answers: [string, ChatAppResponseOrError][];
    isLoading: boolean;
    // eslint-disable-next-line
    error?: any;
    lastQuestionRef: React.MutableRefObject<string>;
}

export const ProjectChat: React.FC<ProjectChatViewProps> = ({ onClose, handleSendQuestion, answers, isLoading, error, lastQuestionRef }) => {
    return (
        <ChatView
            SideComponent={Project}
            sideComponentProps={{}}
            onClose={onClose}
            handleSendQuestion={handleSendQuestion}
            answers={answers}
            isLoading={isLoading}
            error={error}
            lastQuestionRef={lastQuestionRef}
        />
    );
};
