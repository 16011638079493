import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import Account from "../../pages/account/Account";
import React, { useEffect } from "react";
import { recordEvent } from "../../util/utils";
import { useMediaQuery } from "@mui/material";

interface CheckoutModalProps {
    isOpen: boolean;
    toggleModal: () => void;
    userEmail: string | null;
}

export const CheckoutModal: React.FC<CheckoutModalProps> = ({ isOpen, toggleModal, userEmail }) => {
    const isMobile = useMediaQuery("(max-width:844px)");

    useEffect(() => {
        if (isOpen) {
            recordEvent("Checkout Modal Opened", {});
        }
    }, [isOpen]);

    return (
        <Modal open={isOpen} onClose={toggleModal} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ModalDialog layout={isMobile ? "fullscreen" : "center"} sx={!isMobile ? { minWidth: "1400px", minHeight: "400px" } : { overflowY: "scroll" }}>
                <Account userEmail={userEmail || ""} />
                <Typography level={"body-xs"}>No lock-in. Cancel your monthly subscription at any time.</Typography>
                <ModalClose />
            </ModalDialog>
        </Modal>
    );
};
