import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, Card, Button, Skeleton } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import AddIcon from "@mui/icons-material/Add";
import { createProject, deleteProject, listProjects } from "../../../src/api";
import ProjectCard from "../../components/ProjectsComponents/ProjectCard";
import CreateProjectModal from "../../components/ProjectsComponents/CreateProjectModal";
import DeleteProjectModal from "../../components/ProjectsComponents/DeleteProjectModal";
import { Project } from "../../components/ProjectsComponents/types";
import { useSnackbar } from "../../contexts/useSnackbar";
import { UserContext } from "../../contexts/UserContext";
import So_empty_Robot from "../../assets/So_empty_Robot.webp";
import useMediaQuery from "@mui/material/useMediaQuery";

const SkeletonResult = () => (
    <Box sx={{ p: 1 }}>
        <Card
            size={"lg"}
            sx={{
                width: 280,
                height: 225,
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Skeleton animation="wave" variant="text" />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                <Skeleton animation="wave" variant="text" width={60} />
            </Box>
            <Skeleton animation="wave" variant="text" width={60} />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box />
                <Button variant="solid" size="sm">
                    Explore
                    <Skeleton animation="wave" />
                </Button>
            </Box>
        </Card>
    </Box>
);

const Projects: React.FC = () => {
    const { getToken, isSignedIn } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { countryCode } = useParams();
    const currentCountry = countryCode || "au";

    const [projects, setProjects] = useState<Project[]>([]);
    const { updateUser } = useContext(UserContext);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [folderToDelete, setFolderToDelete] = useState<Project | null>(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const { showSnackbar } = useSnackbar();
    const isDesktop = useMediaQuery("(min-width:1200px)");

    const fetchFolders = async () => {
        try {
            setIsLoading(true);
            const data = await listProjects(await getToken({ template: "Standard" }));
            setProjects(data);
        } catch (error) {
            console.error("Error fetching folders:", error);
            showSnackbar({ message: "Failed to fetch projects. Please try again.", variant: "danger" });
            setProjects([]);
        } finally {
            setIsLoading(false);
        }
    };

    const createNewProject = async (name: string, description: string) => {
        try {
            const createdFolder = await createProject(name, description, await getToken({ template: "Standard" }));
            setProjects(prevFolders => [...prevFolders, createdFolder]);
            updateUser();
        } catch (error) {
            console.error("Error creating folder:", error);
        }
    };

    const removeProject = async (id: string) => {
        try {
            await deleteProject(id, await getToken({ template: "Standard" }));
            setProjects(prevFolders => prevFolders.filter(folder => folder.id !== id));
        } catch (error) {
            console.error("Error removing folder:", error);
        }
    };

    useEffect(() => {
        if (!isSignedIn) {
            setIsLoading(false);
            return;
        }
        fetchFolders();
    }, [isSignedIn]);

    const handleTalkToProject = (projectId: string): void => {
        navigate(`/${currentCountry}/projchat/${projectId}`);
    };

    const handleDeleteClick = (project: Project) => {
        setFolderToDelete(project);
        setDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (folderToDelete) {
            await removeProject(folderToDelete.id);
            setDeleteModalOpen(false);
            setFolderToDelete(null);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteModalOpen(false);
        setFolderToDelete(null);
    };

    const handleFolderClick = (folderId: string, folderName: string) => {
        if (isDesktop) {
            navigate(`/${currentCountry}/projchat/${folderId}`, { state: { folderName } });
        } else {
            navigate(`/${currentCountry}/view-projects/${folderId}`, { state: { folderName } });
        }
    };

    const handleOpenCreateModal = () => {
        setCreateModalOpen(true);
    };

    return (
        <Box
            sx={{
                width: { xs: "100%", md: "calc(100% - var(--Sidebar-width))" },
                ml: { xs: 0, md: "var(--Sidebar-width)" },
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                overflowY: "hidden",
                p: 2
            }}
        >
            <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                <Typography level="h4">Project List</Typography>
                <Button
                    disabled={isLoading || !isSignedIn}
                    variant="outlined"
                    color="primary"
                    loading={isLoading}
                    startDecorator={<AddIcon />}
                    onClick={handleOpenCreateModal}
                    sx={{ alignSelf: "flex-start" }}
                >
                    New Project
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: "auto", pt: 2 }}>
                {projects?.length === 0 && !isLoading && isSignedIn ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "left",
                            height: "45%"
                        }}
                    >
                        <Card
                            variant="outlined"
                            sx={{
                                maxWidth: 400,
                                textAlign: "center",
                                p: 4,
                                cursor: "pointer",
                                "&:hover": {
                                    boxShadow: "md",
                                    borderColor: "primary.main"
                                }
                            }}
                            onClick={handleOpenCreateModal}
                        >
                            <img loading="eager" src={So_empty_Robot} alt="No Projects" style={{ width: "200px", height: "auto", margin: "0 auto" }} />
                            <Typography level="body-lg" sx={{ mt: 2 }}>
                                No projects yet. Click here to create a new project!
                            </Typography>
                        </Card>
                    </Box>
                ) : (
                    <Grid container rowSpacing={1} columnSpacing={3} columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} sx={{ ml: "-7px" }}>
                        {!isLoading && isSignedIn ? (
                            projects.map(folder =>
                                folder && folder.id ? (
                                    <Box sx={{ height: "100%", display: "flex", p: 1 }} key={folder.id}>
                                        <ProjectCard
                                            project={folder}
                                            onDelete={() => handleDeleteClick(folder)}
                                            onChat={() => handleTalkToProject(folder.id)}
                                            onClick={() => handleFolderClick(folder.id, folder.name)}
                                        />
                                    </Box>
                                ) : null
                            )
                        ) : !isSignedIn ? (
                            <Box>
                                <Typography>Please sign In to view your projects.</Typography>
                            </Box>
                        ) : (
                            <SkeletonResult />
                        )}
                    </Grid>
                )}
            </Box>
            <CreateProjectModal open={createModalOpen} onClose={() => setCreateModalOpen(false)} onCreateProject={createNewProject} />
            <DeleteProjectModal open={deleteModalOpen} onClose={handleDeleteCancel} onConfirm={handleDeleteConfirm} projectName={folderToDelete?.name || ""} />
        </Box>
    );
};

export default Projects;
