import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListSubheader, Typography, Tooltip, ButtonGroup, Button } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./HistoryMenu.module.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { FavoriteButton } from "../FavoriteButton/FavoriteButton";
import { Conversation, ConversationMap } from "../Sidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "@clerk/clerk-react";
import { listConversations } from "../../api";
import { format, isToday, isYesterday } from "date-fns";

interface HistoryMenuProps {
    open: boolean;
    onClose: () => void;
    groupedConversations: ConversationMap;
    onFavoriteChange?: (convoId: string, isFavorite: boolean) => void;
}

type ViewMode = "recent" | "starred";

export const HistoryMenu: React.FC<HistoryMenuProps> = ({ open, onClose, groupedConversations, onFavoriteChange }) => {
    const navigate = useNavigate();
    const { getToken } = useAuth();
    const [viewMode, setViewMode] = useState<ViewMode>("recent");
    const [localConversations, setLocalConversations] = useState(groupedConversations);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const { countryCode } = useParams();

    // Initial load
    useEffect(() => {
        if (open && !initialized) {
            setLocalConversations(groupedConversations);
            setInitialized(true);
            // Load first page of conversations based on current viewMode
            void loadMoreConversations(1);
        }
    }, [open]);

    const loadMoreConversations = async (currentPage: number = page) => {
        if (loading) return;
        setLoading(true);

        try {
            const token = await getToken();

            const response = await listConversations(token, currentPage, viewMode === "starred");

            if (!response.ok) {
                throw new Error(`API call failed: ${response.status}`);
            }

            const data = await response.json();

            if (data && Array.isArray(data) && data.length > 0) {
                // Group the new conversations by date
                const newGroupedConversations = data.reduce((acc: ConversationMap, convo: Conversation) => {
                    try {
                        // Parse the date string directly without parseISO
                        const date = new Date(convo.created);

                        if (isNaN(date.getTime())) {
                            console.warn("Invalid date format:", convo.created);
                            return acc;
                        }

                        let formattedDate;
                        if (isToday(date)) {
                            formattedDate = "Today";
                        } else if (isYesterday(date)) {
                            formattedDate = "Yesterday";
                        } else {
                            formattedDate = format(date, "MMMM dd, yyyy");
                        }

                        if (!acc[formattedDate]) {
                            acc[formattedDate] = [];
                        }
                        acc[formattedDate].push(convo);
                    } catch (error) {
                        console.warn("Error processing conversation date:", error);
                    }
                    return acc;
                }, {});

                if (currentPage === 1) {
                    setLocalConversations(newGroupedConversations);
                } else {
                    setLocalConversations(prev => {
                        const merged = { ...prev } as ConversationMap;
                        Object.entries(newGroupedConversations).forEach(([date, convos]) => {
                            if (merged[date]) {
                                merged[date] = [...merged[date], ...(convos as Conversation[])];
                            } else {
                                merged[date] = convos as Conversation[];
                            }
                        });
                        return merged;
                    });
                }

                setPage(currentPage + 1);
                setHasMore(data.length > 0);
            } else {
                if (currentPage === 1) {
                    setLocalConversations({});
                }
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error loading conversations:", error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    // Reset and reload when view mode changes
    useEffect(() => {
        if (initialized && open) {
            console.log("View mode changed to:", viewMode);
            setLocalConversations({});
            setPage(1);
            setHasMore(true);
            void loadMoreConversations(1);
        }
    }, [viewMode]);

    const handleFavoriteChange = (convoId: string, isFavorite: boolean) => {
        const updatedConversations = { ...localConversations };
        Object.keys(updatedConversations).forEach(date => {
            updatedConversations[date] = updatedConversations[date].map(convo => (convo.id === convoId ? { ...convo, favorite: isFavorite } : convo));
        });
        setLocalConversations(updatedConversations);

        onFavoriteChange?.(convoId, isFavorite);
    };

    const filteredConversations = React.useMemo(() => {
        if (viewMode === "starred") {
            return Object.keys(localConversations).reduce(
                (acc, date) => {
                    const starredConvos = localConversations[date].filter(convo => convo.favorite);
                    if (starredConvos.length > 0) {
                        acc[date] = starredConvos;
                    }
                    return acc;
                },
                {} as Record<string, Conversation[]>
            );
        }
        return localConversations;
    }, [localConversations, viewMode]);

    if (!open) return null;

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box
                className={styles.historyMenu}
                id="history-menu-scroll"
                sx={{
                    position: "fixed",
                    left: "220px",
                    top: {
                        xs: 0,
                        sm: "65px",
                        [`@media (min-width: 1401px)`]: {
                            top: 0
                        }
                    },
                    height: {
                        xs: "100vh",
                        sm: "calc(100vh - 65px)",
                        [`@media (min-width: 1401px)`]: {
                            height: "100vh"
                        }
                    },
                    width: "400px",
                    backgroundColor: "background.surface",
                    borderRight: "1px solid",
                    borderColor: "divider",
                    overflowY: "auto",
                    zIndex: 1100,
                    boxShadow: "rgba(0, 0, 0, 0.1) 2px 0px 4px"
                }}
            >
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "background.surface",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        p: 2,
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <ButtonGroup variant="soft" size="sm">
                        <Button
                            onClick={() => setViewMode("recent")}
                            variant={viewMode === "recent" ? "solid" : "soft"}
                            color={viewMode === "recent" ? "primary" : "neutral"}
                        >
                            Recent
                        </Button>
                        <Button
                            onClick={() => setViewMode("starred")}
                            variant={viewMode === "starred" ? "solid" : "soft"}
                            color={viewMode === "starred" ? "primary" : "neutral"}
                        >
                            Starred
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box
                    className={styles.chatHistory}
                    sx={{
                        height: "calc(100% - 64px)", // Adjust for the toggle header
                        p: 2,
                        backgroundColor: "var(--joy-palette-background-surface)"
                    }}
                >
                    <InfiniteScroll
                        dataLength={Object.values(localConversations).flat().length}
                        next={loadMoreConversations}
                        hasMore={hasMore}
                        loader={
                            <Typography level="body-sm" sx={{ textAlign: "center", py: 2 }}>
                                Loading...
                            </Typography>
                        }
                        scrollableTarget="history-menu-scroll"
                        endMessage={
                            <Typography level="body-sm" sx={{ textAlign: "center", py: 2 }}>
                                {Object.keys(filteredConversations).length === 0
                                    ? viewMode === "starred"
                                        ? "No starred conversations yet"
                                        : "No conversations yet"
                                    : "No more conversations to load"}
                            </Typography>
                        }
                        style={{ overflow: "visible" }}
                    >
                        <List>
                            {Object.keys(filteredConversations).map((date: string) => (
                                <ListItem nested key={date}>
                                    <ListSubheader>{date}</ListSubheader>
                                    <List>
                                        {filteredConversations[date].map((convo: Conversation) => (
                                            <ListItem key={convo.id} sx={{ mt: 0.25 }}>
                                                {convo.messages[0]?.question && (
                                                    <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                                                        <FavoriteButton
                                                            id={convo.id}
                                                            type="conversation"
                                                            initialFavoriteState={convo.favorite || false}
                                                            size="sm"
                                                            className={styles.historyFavoriteButton}
                                                            onFavoriteChange={isFavorite => handleFavoriteChange(convo.id, isFavorite)}
                                                        />
                                                        <Tooltip title={convo.messages[0]?.question} placement="right">
                                                            <Box
                                                                onClick={() => {
                                                                    let path = `/${countryCode}/?conversationId=${convo.id}`;
                                                                    if (convo.type === "pdf") {
                                                                        path = `/${countryCode}/pdf-chat/?conversationId=${convo.id}`;
                                                                    } else if (convo.type === "project") {
                                                                        path = `/${countryCode}/projchat/${convo.project_id}?conversationId=${convo.id}`;
                                                                    }
                                                                    navigate(path, {
                                                                        state: { pdfUrl: convo.url, documentId: convo.doc_id, project_id: convo.project_id }
                                                                    });
                                                                    onClose();
                                                                }}
                                                                sx={{
                                                                    cursor: "pointer",
                                                                    p: 1,
                                                                    borderRadius: 1,
                                                                    width: "100%",
                                                                    transition: "background-color 0.2s ease",
                                                                    "&:hover": {
                                                                        backgroundColor: "var(--joy-palette-neutral-100, #f0f4f8)"
                                                                    }
                                                                }}
                                                            >
                                                                <Typography
                                                                    level="body-sm"
                                                                    sx={{
                                                                        display: "-webkit-box",
                                                                        WebkitLineClamp: 2,
                                                                        WebkitBoxOrient: "vertical",
                                                                        overflow: "hidden",
                                                                        lineHeight: "1.2em",
                                                                        maxHeight: "2.4em"
                                                                    }}
                                                                >
                                                                    {convo.messages[0]?.question}
                                                                </Typography>
                                                            </Box>
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            ))}
                        </List>
                    </InfiniteScroll>
                </Box>
            </Box>
        </ClickAwayListener>
    );
};
