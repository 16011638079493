import { Outlet, Link, useSearchParams, useParams } from "react-router-dom";
import styles from "./Layout.module.css";
import { Box, IconButton } from "@mui/joy";
import { Sidebar } from "../../components/Sidebar";
import React, { useState } from "react";
import logo from "../../assets/logo_b.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Authentication } from "../../components/Authentication";
import { useSwipeable } from "react-swipeable";
import { useMediaQuery } from "@mui/material";
import { CountryProvider } from "../../contexts/CountryContext";
import { UserProvider } from "../../contexts/UserContext";

const Layout = () => {
    const [, setSearchParams] = useSearchParams();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const isDesktop = useMediaQuery("(min-width:1401px)");

    const reloadPage = () => {
        setSearchParams(new URLSearchParams());
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (!isDesktop) {
                setSidebarOpen(false);
            }
        },
        onSwipedRight: () => {
            if (!isDesktop) {
                setSidebarOpen(true);
            }
        }
    });

    return (
        <CountryProvider>
            <UserProvider>
                <div {...swipeHandlers}>
                    <div className={styles.layout}>
                        <div className={styles.bottomBar}>
                            {!isDesktop && (
                                <div className={styles.headerContainer}>
                                    <IconButton
                                        data-testid="menu-button"
                                        onClick={toggleSidebar}
                                        sx={{
                                            display: { xs: "flex", xl: "none" }
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Link to={"/"} onClick={() => reloadPage()} className={styles.headerTitleContainer}>
                                        <img className={styles.headerLogo} src={logo} />
                                    </Link>
                                    <div>
                                        <Authentication />
                                    </div>
                                </div>
                            )}
                        </div>
                        <Box className={styles.body}>
                            {!isDesktop && <Sidebar sidebarOpen={sidebarOpen} onClose={closeSidebar} />}
                            {isDesktop && <Sidebar sidebarOpen={true} />}
                            <Outlet />
                        </Box>
                    </div>
                </div>
            </UserProvider>
        </CountryProvider>
    );
};

export default Layout;
