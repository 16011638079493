import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import Layout from "./pages/layout/Layout";
import { ClerkProvider } from "@clerk/clerk-react";
import { ThemeProvider, extendTheme } from "@mui/joy";
import mixpanel from "mixpanel-browser";
import Hotjar from "@hotjar/browser";
import Feed from "./pages/feed/Feed";
import Chat from "./pages/chat/Chat";
import PDFChatViewWrapper from "./pages/pdfChat/PdfChatContainer";
import Project from "./components/ProjectsComponents/FolderContent";
import ProjectChatWrapper from "./pages/projects/ProjectChatContainer";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import Projects from "./pages/projects/Projects";
import { useMediaQuery } from "@mui/material";

const siteId = 3741740;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

mixpanel.init("6cdb7f6e2e72e0a8b19c647a4771e450", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage"
});

initializeIcons();

declare global {
    interface Window {
        $sleek: [];
        SLEEK_PRODUCT_ID: number;
    }
}

mixpanel.init("YOUR_MIXPANEL_TOKEN");

const App = () => {
    const isMobile = useMediaQuery("(max-width:844px)");

    if (!isMobile) {
        window["$sleek"] = [];
        window["SLEEK_PRODUCT_ID"] = 38395668;
        (function () {
            const d = document;
            const s = d.createElement("script");
            s.src = "https://client.sleekplan.com/sdk/e.js";
            s.async = true;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();
    }
    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    );
};

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

const VITE_CLERK_PUBLISHABLE_KEY = import.meta.env.DEV ? "pk_test_bm9ibGUtY3JheWZpc2gtMzYuY2xlcmsuYWNjb3VudHMuZGV2JA" : "pk_live_Y2xlcmsuY291cnRhaWQuYWkk";

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                danger: {
                    solidBg: "#9c0000"
                },
                primary: {
                    solidBg: "#264677",
                    plainColor: "#264677",
                    solidBorder: "#264677",
                    solidActiveBg: "#264677",
                    outlinedColor: "#264677",
                    plainActiveColor: "#264677"
                }
            }
        }
    }
});

// Refactored router: All routes are now prefixed with a country code (/:countryCode)
const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/au" />
    },
    {
        path: "/:countryCode",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "pdf-chat",
                element: <PDFChatViewWrapper />
            },
            {
                path: "projchat/:projectId",
                element: <ProjectChatWrapper />
            },
            {
                path: "view-projects",
                children: [
                    {
                        index: true,
                        element: <Projects />
                    },
                    {
                        path: ":projectId",
                        element: <Project />
                    }
                ]
            },
            {
                path: "casenotes",
                children: [
                    {
                        index: true,
                        element: <Feed />
                    },
                    {
                        path: "*",
                        lazy: () => import("./pages/NoPage")
                    }
                ]
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(rootElement).render(
    <ClerkProvider
        publishableKey={VITE_CLERK_PUBLISHABLE_KEY}
        appearance={{
            layout: {
                termsPageUrl: "https://courtaid.ai/terms-and-conditions/",
                privacyPageUrl: "https://courtaid.ai/privacy-policy/"
            }
        }}
    >
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <RouterProvider router={router} />
            </SnackbarProvider>
        </ThemeProvider>
    </ClerkProvider>
);

const loader = document.getElementById("global-loader");
if (loader) loader.remove();
