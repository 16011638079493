import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListSubheader, Typography, ButtonGroup, Button, IconButton } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { FavoriteButton } from "../FavoriteButton/FavoriteButton";
import { getDocuments } from "../../api";
import { format, isToday, isYesterday, parseISO } from "date-fns";
import { SavedDocument } from "../../api/models";
import { OptionsMenu } from "../OptionsMenu/OptionsMenu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SourceActionPopup } from "../SourceActionPopup";
import InfiniteScroll from "react-infinite-scroll-component";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { isPDF } from "../../util/utils";
import { useTheme } from "@mui/joy/styles";
import { useMediaQuery } from "@mui/material";

interface DocMenuProps {
    open: boolean;
    onClose: () => void;
}

type ViewMode = "recent" | "starred";

export const DocMenu: React.FC<DocMenuProps> = ({ open, onClose }) => {
    const navigate = useNavigate();
    const { getToken } = useAuth();
    const [viewMode, setViewMode] = useState<ViewMode>("recent");
    const [documents, setDocuments] = useState<SavedDocument[]>([]);
    const [favoriteStates, setFavoriteStates] = useState<Record<string, boolean>>({});
    const [forceUpdate, setForceUpdate] = useState(0);
    const [activeMenuId, setActiveMenuId] = useState<string | null>(null);
    const [selectedDoc, setSelectedDoc] = useState<SavedDocument | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const { countryCode } = useParams();

    // Initial load
    useEffect(() => {
        if (open && !initialized) {
            resetAndLoad();
            setInitialized(true);
        }
    }, [open]);

    // Add cleanup when component unmounts
    useEffect(() => {
        return () => {
            setInitialized(false);
        };
    }, []);

    const resetAndLoad = async () => {
        // Reset all state first
        setDocuments([]);
        setFavoriteStates({});
        setPage(1);
        setHasMore(true);
        setLoading(false);

        // Then load new documents
        await loadMoreDocuments(1);
    };

    const loadMoreDocuments = async (currentPage: number = page) => {
        if (loading) return;
        setLoading(true);

        try {
            const token = await getToken();
            const response = await getDocuments(token, false, viewMode === "starred", currentPage);

            if (!response.ok) {
                throw new Error(`API call failed: ${response.status}`);
            }

            const data = await response.json();

            if (data.documents && data.documents.length > 0) {
                setDocuments(prev => {
                    const newDocs = [...prev, ...data.documents];
                    return newDocs;
                });

                // Update favorite states based on the favourite field
                const newFavoriteStates = { ...favoriteStates };
                data.documents.forEach((doc: SavedDocument) => {
                    newFavoriteStates[doc.doc_id] = !!doc.favourite;
                });
                setFavoriteStates(prev => ({ ...prev, ...newFavoriteStates }));

                setHasMore(data.has_more);
                setPage(currentPage + 1);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error loading more documents:", error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    // Reset documents when switching view modes
    useEffect(() => {
        if (initialized && open) {
            void resetAndLoad();
        }
    }, [viewMode]);

    const handleFavoriteChange = (docId: string, isFavorite: boolean) => {
        // Update favorite states
        setFavoriteStates(prev => ({
            ...prev,
            [docId]: isFavorite
        }));

        // Update documents list
        setDocuments(prevDocs => {
            const updatedDocs = prevDocs.map(doc => {
                if (doc.doc_id === docId) {
                    return {
                        ...doc,
                        favourite: isFavorite ? new Date().toISOString() : null
                    };
                }
                return doc;
            });
            return updatedDocs;
        });

        // Force a re-render of filtered documents
        setForceUpdate(prev => prev + 1);
    };

    // Filter documents based on current view mode
    const filteredDocuments = React.useMemo(() => {
        if (viewMode === "starred") {
            return documents.filter(doc => favoriteStates[doc.doc_id]);
        }
        return documents;
    }, [documents, favoriteStates, viewMode, forceUpdate]);

    const groupDocumentsByDate = (documents: SavedDocument[]) => {
        return documents.reduce((groups: Record<string, SavedDocument[]>, doc) => {
            try {
                // Try to parse the updated date first, fall back to viewed date
                const dateStr = doc.viewed || doc.updated;
                if (!dateStr) {
                    console.warn("Document has no valid date:", doc);
                    return groups;
                }

                // Ensure we parse the date correctly
                let date: Date;
                if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(dateStr)) {
                    // If it's an ISO string, parse it directly
                    date = parseISO(dateStr);
                } else {
                    // Otherwise, try creating a Date object
                    date = new Date(dateStr);
                }

                if (isNaN(date.getTime())) {
                    console.warn("Invalid date:", dateStr);
                    return groups;
                }

                let formattedDate: string;
                if (isToday(date)) {
                    formattedDate = "Today";
                } else if (isYesterday(date)) {
                    formattedDate = "Yesterday";
                } else {
                    formattedDate = format(date, "MMMM dd, yyyy");
                }

                if (!groups[formattedDate]) {
                    groups[formattedDate] = [];
                }
                groups[formattedDate].push(doc);
            } catch (error) {
                console.warn("Error processing date for document:", doc, error);
            }
            return groups;
        }, {});
    };

    // Group documents by date
    const groupedDocuments = React.useMemo(() => {
        return groupDocumentsByDate(filteredDocuments);
    }, [filteredDocuments]);

    // Add effect to reset activeMenuId when DocMenu closes
    useEffect(() => {
        if (!open) {
            setActiveMenuId(null);
        }
    }, [open]);

    const handleMenuToggle = (docId: string, isOpen: boolean) => {
        setActiveMenuId(isOpen ? docId : null);
    };

    // Add handler for mobile document options
    const handleDocumentOptions = (doc: SavedDocument) => {
        if (isMobile) {
            // Create a properly formatted document object
            const formattedDoc = {
                ...doc.data,
                doc_id: doc.doc_id,
                id: doc.doc_id,
                name: doc.data.name,
                url: doc.data.pdf_url || doc.data.url,
                summary1: doc.data.summary1
            };
            setSelectedDoc({ ...doc, data: formattedDoc });
        }
    };

    // Add handler to close the popup
    const handleClosePopup = () => {
        setSelectedDoc(null);
    };

    // Add handler to close both menus
    const handleCloseAllMenus = () => {
        handleClosePopup(); // Close SourceActionPopup
        onClose(); // Close DocMenu
    };

    if (!open) return null;

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box
                sx={{
                    position: "fixed",
                    left: {
                        xs: 0,
                        sm: "220px"
                    },
                    top: {
                        xs: "65px",
                        sm: "65px",
                        [`@media (min-width: 1401px)`]: {
                            top: 0
                        }
                    },
                    height: {
                        xs: "calc(100vh - 65px)",
                        sm: "calc(100vh - 65px)",
                        [`@media (min-width: 1401px)`]: {
                            height: "100vh"
                        }
                    },
                    width: {
                        xs: "100vw",
                        sm: "400px"
                    },
                    backgroundColor: "background.surface",
                    borderRight: "1px solid",
                    borderColor: "divider",
                    overflowY: "auto",
                    zIndex: 1100,
                    boxShadow: "rgba(0, 0, 0, 0.1) 2px 0px 4px"
                }}
                id="doc-menu-scroll"
            >
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "background.surface",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2
                    }}
                >
                    {/* Add Mobile Header */}
                    <Box
                        sx={{
                            display: { xs: "flex", sm: "none" },
                            alignItems: "center",
                            gap: 1
                        }}
                    >
                        <IconButton onClick={onClose} variant="plain">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography level="title-lg">Documents</Typography>
                    </Box>

                    {/* Existing button group */}
                    <ButtonGroup variant="soft" size="sm" sx={{ alignSelf: "center" }}>
                        <Button
                            onClick={() => setViewMode("recent")}
                            variant={viewMode === "recent" ? "solid" : "soft"}
                            color={viewMode === "recent" ? "primary" : "neutral"}
                        >
                            Recent
                        </Button>
                        <Button
                            onClick={() => setViewMode("starred")}
                            variant={viewMode === "starred" ? "solid" : "soft"}
                            color={viewMode === "starred" ? "primary" : "neutral"}
                        >
                            Starred
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box sx={{ p: 2 }}>
                    <InfiniteScroll
                        dataLength={filteredDocuments.length}
                        next={() => loadMoreDocuments(page)}
                        hasMore={hasMore}
                        loader={
                            <Typography level="body-sm" sx={{ textAlign: "center", py: 2 }}>
                                Loading more documents...
                            </Typography>
                        }
                        scrollableTarget="doc-menu-scroll"
                        endMessage={
                            <Typography level="body-sm" sx={{ textAlign: "center", py: 2 }}>
                                {filteredDocuments.length === 0
                                    ? viewMode === "starred"
                                        ? "No starred documents"
                                        : "No documents found"
                                    : `Loaded ${filteredDocuments.length} documents`}
                            </Typography>
                        }
                    >
                        <List>
                            {Object.entries(groupedDocuments).map(([date, docs]) => (
                                <ListItem nested key={date}>
                                    <ListSubheader>{date}</ListSubheader>
                                    <List>
                                        {docs.map(doc => (
                                            <ListItem key={doc.id}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        width: "100%",
                                                        alignItems: "flex-start",
                                                        gap: 1,
                                                        py: 0.5
                                                    }}
                                                >
                                                    <FavoriteButton
                                                        id={doc.doc_id}
                                                        type="document"
                                                        initialFavoriteState={favoriteStates[doc.doc_id] || false}
                                                        size="sm"
                                                        onFavoriteChange={isFavorite => handleFavoriteChange(doc.doc_id, isFavorite)}
                                                    />
                                                    <Box
                                                        onClick={() => {
                                                            // Check both pdf_url and url for PDF
                                                            const pdfUrl = doc.data.pdf_url || (isPDF(doc.data.url) ? doc.data.url : null);
                                                            if (pdfUrl) {
                                                                navigate(`/${countryCode}/pdf-chat`, {
                                                                    state: {
                                                                        pdfUrl,
                                                                        documentId: doc.doc_id,
                                                                        documentName: doc.data.name,
                                                                        fullDocument: {
                                                                            ...doc.data,
                                                                            doc_id: doc.doc_id,
                                                                            id: doc.doc_id
                                                                        }
                                                                    }
                                                                });
                                                                onClose();
                                                            }
                                                        }}
                                                        sx={{
                                                            cursor: doc.data.pdf_url || isPDF(doc.data.url) ? "pointer" : "default",
                                                            p: 1,
                                                            borderRadius: 1,
                                                            flexGrow: 1,
                                                            "&:hover": {
                                                                backgroundColor: doc.data.pdf_url || isPDF(doc.data.url) ? "action.hover" : "transparent"
                                                            }
                                                        }}
                                                    >
                                                        <Typography
                                                            level="body-sm"
                                                            sx={{
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: "vertical",
                                                                overflow: "hidden",
                                                                lineHeight: "1.2em",
                                                                maxHeight: "2.4em",
                                                                mb: 0.5,
                                                                color: doc.data.pdf_url || isPDF(doc.data.url) ? "text.primary" : "neutral.500",
                                                                textDecoration: "none",
                                                                "&:hover": {
                                                                    textDecoration: doc.data.pdf_url || isPDF(doc.data.url) ? "underline" : "none"
                                                                }
                                                            }}
                                                        >
                                                            {doc.data.name || "Untitled Document"}
                                                        </Typography>
                                                        <Typography level="body-xs" color="neutral">
                                                            {(() => {
                                                                const dateStr = doc.viewed || doc.updated;
                                                                if (!dateStr) return "Unknown Date";

                                                                const parsedDate = new Date(dateStr);
                                                                if (isNaN(parsedDate.getTime())) {
                                                                    console.warn("Invalid date:", dateStr);
                                                                    return "Invalid Date";
                                                                }

                                                                return doc.viewed
                                                                    ? `Viewed ${format(parsedDate, "h:mm a")}`
                                                                    : `Updated ${format(parsedDate, "h:mm a")}`;
                                                            })()}
                                                        </Typography>
                                                    </Box>
                                                    {isMobile ? (
                                                        <IconButton onClick={() => handleDocumentOptions(doc)} variant="plain" color="neutral" size="sm">
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <OptionsMenu
                                                            docId={doc.doc_id}
                                                            docUrl={doc.data.url}
                                                            docName={doc.data.name}
                                                            summary1={doc.data.summary1}
                                                            pdf_url={doc.data.pdf_url}
                                                            isActive={activeMenuId === doc.doc_id}
                                                            onMenuToggle={isOpen => handleMenuToggle(doc.doc_id, isOpen)}
                                                        />
                                                    )}
                                                </Box>
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            ))}
                        </List>
                    </InfiniteScroll>
                </Box>

                {/*@ts-expect-error i cant deal with this*/}
                <SourceActionPopup open={!!selectedDoc} onClose={handleClosePopup} resultDocument={selectedDoc?.data} onMenuAction={handleCloseAllMenus} />
            </Box>
        </ClickAwayListener>
    );
};
