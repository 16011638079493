import React, { useState, useEffect } from "react";
import { Box, Typography, ButtonGroup, Button, IconButton, List, ListItem, ListSubheader, Modal } from "@mui/joy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FavoriteButton } from "../FavoriteButton/FavoriteButton";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./HistoryMenu.module.css";
import { Conversation, ConversationMap } from "../Sidebar";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "@clerk/clerk-react";
import { listConversations } from "../../api";
import { format, isToday, isYesterday } from "date-fns";

interface MobileHistoryMenuProps {
    open: boolean;
    onClose: () => void;
    groupedConversations: ConversationMap;
    onSidebarClose?: () => void;
    onFavoriteChange?: (convoId: string, isFavorite: boolean) => void;
}

type ViewMode = "recent" | "starred";

export const MobileHistoryMenu: React.FC<MobileHistoryMenuProps> = ({ open, onClose, groupedConversations, onSidebarClose, onFavoriteChange }) => {
    const navigate = useNavigate();
    const { getToken } = useAuth();
    const { countryCode } = useParams();
    const [viewMode, setViewMode] = useState<ViewMode>("recent");
    const [localConversations, setLocalConversations] = useState(groupedConversations);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);

    // Initial load
    useEffect(() => {
        if (open && !initialized) {
            setLocalConversations(groupedConversations);
            setInitialized(true);
            void loadMoreConversations(1);
        }
    }, [open]);

    // Add cleanup when component unmounts
    useEffect(() => {
        return () => {
            setInitialized(false);
        };
    }, []);

    const loadMoreConversations = async (currentPage: number = page) => {
        if (loading) return;
        setLoading(true);

        try {
            const token = await getToken();

            const response = await listConversations(token, currentPage, viewMode === "starred");

            if (!response.ok) {
                throw new Error(`API call failed: ${response.status}`);
            }

            const data = await response.json();

            if (data && Array.isArray(data) && data.length > 0) {
                const newGroupedConversations = data.reduce((acc: ConversationMap, convo: Conversation) => {
                    try {
                        const date = new Date(convo.created);

                        if (isNaN(date.getTime())) {
                            console.warn("Invalid date format:", convo.created);
                            return acc;
                        }

                        let formattedDate;
                        if (isToday(date)) {
                            formattedDate = "Today";
                        } else if (isYesterday(date)) {
                            formattedDate = "Yesterday";
                        } else {
                            formattedDate = format(date, "MMMM dd, yyyy");
                        }

                        if (!acc[formattedDate]) {
                            acc[formattedDate] = [];
                        }
                        acc[formattedDate].push(convo);
                    } catch (error) {
                        console.warn("Error processing conversation date:", error);
                    }
                    return acc;
                }, {});

                if (currentPage === 1) {
                    setLocalConversations(newGroupedConversations);
                } else {
                    setLocalConversations(prev => {
                        const merged = { ...prev } as ConversationMap;
                        Object.entries(newGroupedConversations).forEach(([date, convos]) => {
                            if (merged[date]) {
                                merged[date] = [...merged[date], ...(convos as Conversation[])];
                            } else {
                                merged[date] = convos as Conversation[];
                            }
                        });
                        return merged;
                    });
                }

                setPage(currentPage + 1);
                setHasMore(data.length > 0);
            } else {
                if (currentPage === 1) {
                    setLocalConversations({});
                }
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error loading conversations:", error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    // Reset and reload when view mode changes
    useEffect(() => {
        if (initialized && open) {
            setLocalConversations({});
            setPage(1);
            setHasMore(true);
            void loadMoreConversations(1);
        }
    }, [viewMode]);

    const handleFavoriteChange = (convoId: string, isFavorite: boolean) => {
        const updatedConversations = { ...localConversations };
        Object.keys(updatedConversations).forEach(date => {
            updatedConversations[date] = updatedConversations[date].map(convo => (convo.id === convoId ? { ...convo, favorite: isFavorite } : convo));
        });
        setLocalConversations(updatedConversations);
        onFavoriteChange?.(convoId, isFavorite);
    };

    const handleConversationClick = (convo: Conversation) => {
        let path = `/${countryCode}/?conversationId=${convo.id}`;
        if (convo.type === "pdf") {
            path = `/${countryCode}/pdf-chat/?conversationId=${convo.id}`;
        } else if (convo.type === "project") {
            path = `/${countryCode}/projchat/${convo.project_id}?conversationId=${convo.id}`;
        }

        navigate(path, {
            state: {
                pdfUrl: convo.url,
                documentId: convo.doc_id,
                project_id: convo.project_id
            }
        });
        onClose();
        onSidebarClose?.();
    };

    const filteredConversations = React.useMemo(() => {
        if (viewMode === "starred") {
            return Object.keys(localConversations).reduce(
                (acc, date) => {
                    const starredConvos = localConversations[date].filter(convo => convo.favorite);
                    if (starredConvos.length > 0) {
                        acc[date] = starredConvos;
                    }
                    return acc;
                },
                {} as Record<string, Conversation[]>
            );
        }
        return localConversations;
    }, [localConversations, viewMode]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Box
                data-mobile-history-menu="true"
                className={styles.historyMenu}
                sx={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    height: "100vh",
                    width: "100%",
                    backgroundColor: "background.surface",
                    zIndex: 1200,
                    overflowY: "auto"
                }}
            >
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "background.surface",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <IconButton onClick={onClose} variant="plain">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography level="title-lg">History</Typography>
                    </Box>
                    <ButtonGroup
                        variant="soft"
                        size="sm"
                        sx={{
                            alignSelf: "center"
                        }}
                    >
                        <Button
                            onClick={() => setViewMode("recent")}
                            variant={viewMode === "recent" ? "solid" : "soft"}
                            color={viewMode === "recent" ? "primary" : "neutral"}
                        >
                            Recent
                        </Button>
                        <Button
                            onClick={() => setViewMode("starred")}
                            variant={viewMode === "starred" ? "solid" : "soft"}
                            color={viewMode === "starred" ? "primary" : "neutral"}
                        >
                            Starred
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box
                    className={`${styles.chatHistory} ${styles.chatHistoryScrollbar}`}
                    id="mobile-chat-history-scroll"
                    sx={{
                        height: "calc(100% - 64px)",
                        overflowY: "auto",
                        overflowX: "hidden",
                        p: 2
                    }}
                >
                    <InfiniteScroll
                        dataLength={Object.values(localConversations).flat().length}
                        next={loadMoreConversations}
                        hasMore={hasMore}
                        loader={
                            <Typography level="body-sm" sx={{ textAlign: "center", py: 2 }}>
                                Loading...
                            </Typography>
                        }
                        scrollableTarget="mobile-chat-history-scroll"
                        endMessage={
                            <Typography level="body-sm" sx={{ textAlign: "center", py: 2 }}>
                                {Object.keys(filteredConversations).length === 0
                                    ? viewMode === "starred"
                                        ? "No starred conversations yet"
                                        : "No conversations yet"
                                    : "No more conversations to load"}
                            </Typography>
                        }
                    >
                        <List>
                            {Object.entries(filteredConversations).map(([date, conversations]) => (
                                <ListItem key={date} nested>
                                    <ListSubheader>{date}</ListSubheader>
                                    <List>
                                        {conversations.map(convo => (
                                            <ListItem key={convo.id} sx={{ mt: 0.25 }}>
                                                {convo.messages[0]?.question && (
                                                    <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                                                        <FavoriteButton
                                                            id={convo.id}
                                                            type="conversation"
                                                            initialFavoriteState={convo.favorite || false}
                                                            size="sm"
                                                            className={styles.historyFavoriteButton}
                                                            onFavoriteChange={isFavorite => handleFavoriteChange(convo.id, isFavorite)}
                                                        />
                                                        <Box
                                                            onClick={() => handleConversationClick(convo)}
                                                            sx={{
                                                                cursor: "pointer",
                                                                p: 1,
                                                                borderRadius: 1,
                                                                width: "100%",
                                                                "&:hover": {
                                                                    backgroundColor: "var(--joy-palette-neutral-100, #f0f4f8)"
                                                                }
                                                            }}
                                                        >
                                                            <Typography
                                                                level="body-sm"
                                                                sx={{
                                                                    display: "-webkit-box",
                                                                    WebkitLineClamp: 2,
                                                                    WebkitBoxOrient: "vertical",
                                                                    overflow: "hidden",
                                                                    lineHeight: "1.2em",
                                                                    maxHeight: "2.4em"
                                                                }}
                                                            >
                                                                {convo.messages[0]?.question}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            ))}
                        </List>
                    </InfiniteScroll>
                </Box>
            </Box>
        </Modal>
    );
};
