import React from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

interface DeleteProjectModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    projectName: string;
}

const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({ open, onClose, onConfirm, projectName }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <DialogTitle>Delete Project</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete the project "{projectName}"? This action cannot be undone.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="plain" color="neutral" onClick={onClose}>
                        No
                    </Button>
                    <Button variant="solid" color="danger" onClick={onConfirm}>
                        Yes, Delete
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

export default DeleteProjectModal;
