import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ProjectChat } from "./ProjectChat";
import { useAuth } from "@clerk/clerk-react";
import { ChatAppRequest, ChatAppResponse, ChatAppResponseOrError, chatProjectApi, getConversation, ResponseMessage } from "../../api";
import loading_animation_1 from "../../assets/loading_animation_1.gif";
import loading_animation_2 from "../../assets/loading_animation_2.gif";
import loading_animation_3 from "../../assets/loading_animation_3.gif";
import { animations } from "../../util/utils";

const ProjectChatWrapper = () => {
    const navigate = useNavigate();
    const { projectId } = useParams(); // Extract folderId from the route
    const [conversationId, setConversationId] = useState<string | undefined>(undefined);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const { getToken } = useAuth();
    const lastQuestionRef = useRef<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (conversationId === searchParams.get("conversationId")) {
            return;
        } else if (searchParams.get("conversationId")) {
            loadConversation(searchParams.get("conversationId"));
            setConversationId(searchParams.get("conversationId") || undefined);
        }
    }, [searchParams]);

    const loadConversation = async (conversationId: string | null) => {
        if (!conversationId) {
            return;
        }
        const resp = await getConversation(conversationId, await getToken({ template: "Standard" }));
        const parsedResp = await resp.json();
        setAnswers(parsedResp["messages"]);
        // @ts-expect-error - parsedResp is not null
        parsedResp["messages"].forEach(message => {
            message[1].choices[0].message.content = message[1].choices[0].message.content.replace(
                /<\[(\d+)\]>/g,
                (_: string, p1: string) => `<Quote index="${p1}" > </Quote>`
            );
        });
        lastQuestionRef.current = parsedResp["messages"][parsedResp["messages"].length - 1][0];
    };

    // Add GIF preloading
    useEffect(() => {
        const preloadGifs = async () => {
            const urls = [loading_animation_1, loading_animation_2, loading_animation_3];
            const loadedGifs = await Promise.all(
                urls.map(async url => {
                    const response = await fetch(url);
                    return URL.createObjectURL(await response.blob());
                })
            );
            animations.push(...loadedGifs);
        };
        preloadGifs();
    }, []);

    if (!projectId) {
        return;
    }

    const handleSendQuestion = async (question: string) => {
        lastQuestionRef.current = question;
        setIsLoading(true);
        setError(undefined);

        const messages: ResponseMessage[] = answers.flatMap(a => [
            { content: a[0], role: "user" },
            { content: a[1].choices[0].message.content, role: "assistant" }
        ]);

        try {
            const request: ChatAppRequest = {
                conversation_id: conversationId || undefined,
                messages: [...messages, { content: question, role: "user" }],
                search: question,
                stream: false,
                context: {
                    chat_type: "project",
                    overrides: {
                        suggest_followup_questions: true,
                        project_id: parseInt(projectId)
                    }
                },
                session_state: null
            };

            const response = await chatProjectApi(
                request,
                await getToken({
                    template: "Standard"
                })
            );
            if (!response.body) {
                throw new Error("No response body");
            }

            const parsedResponse: ChatAppResponseOrError = await response.json();
            if (response.status > 299 || !response.ok) {
                throw new Error(parsedResponse.error || "Unknown error");
            }
            if (parsedResponse.error || !parsedResponse.choices) {
                if (parsedResponse.error && parsedResponse.error.includes("Rate limit is exceeded.")) {
                    throw new Error(`Our servers are currently at full capacity due to high demand. Please try again in a minute.`);
                }
                throw new Error("Service error, please try again. If the problem persists, contact support.");
            }

            // @ts-expect-error - parsedResponse is not null
            setAnswers([...answers, [question, parsedResponse]]);
            setConversationId(String(parsedResponse.conversation_id));
        } catch (e) {
            console.log(e);
            setError("An Error Occurred. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        navigate(-1);
    };

    return (
        <ProjectChat
            answers={answers}
            handleSendQuestion={handleSendQuestion}
            isLoading={isLoading}
            onClose={handleClose}
            error={error}
            lastQuestionRef={lastQuestionRef}
        />
    );
};

export default ProjectChatWrapper;
