import React from "react";
import { Button } from "@mui/joy";
import { Launch } from "@mui/icons-material";
import { DocumentResult } from "../../api";
import { useDocumentView } from "../useDocumentView/useDocumentView";
import { SxProps } from "@mui/joy/styles/types";

interface ViewOriginalButtonProps {
    document: Partial<DocumentResult>;
    variantOverride?: "plain" | "soft" | "outlined";
    onComplete?: () => void;
    isMenuButton?: boolean;
    sx?: SxProps;
    mobileView?: boolean;
}

export const ViewOriginalButton: React.FC<ViewOriginalButtonProps> = ({ document, variantOverride, onComplete, isMenuButton = false, mobileView, sx }) => {
    const { handleDocumentView } = useDocumentView();

    const handleClick = async (e: React.MouseEvent) => {
        e.stopPropagation();

        // Mark document as viewed
        if (document.doc_id) {
            await handleDocumentView(document.doc_id, document);
        }

        // Open the URL in a new tab
        window.open(document.url, "_blank");

        // Call onComplete if provided
        onComplete?.();
    };

    return (
        <Button
            component={isMenuButton ? "div" : "button"}
            startDecorator={mobileView ? undefined : <Launch />}
            color={isMenuButton ? "neutral" : "primary"}
            variant={variantOverride || "plain"}
            size="md"
            onClick={handleClick}
            sx={{
                ...(isMenuButton ? {} : { borderColor: "white" }),
                ...sx
            }}
        >
            {mobileView ? <Launch /> : "Original"}
        </Button>
    );
};
