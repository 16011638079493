import { Box, Chip } from "@mui/joy";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { recordEvent } from "../../util/utils";

interface ChatTypeSelectorProps {
    onChatTypeChange?: (chatType: string) => void;
}

export const ChatTypeSelector = ({ onChatTypeChange }: ChatTypeSelectorProps): JSX.Element => {
    const [searchParams] = useSearchParams();
    const location = window.location.pathname;

    const getDefaultChatType = () => {
        if (location.includes("/casenotes") || location.includes("/find")) {
            return "find";
        }
        return "chat";
    };

    const currentChatType = location === "/" ? searchParams.get("chatType") || "chat" : getDefaultChatType();
    const [selected, setSelected] = React.useState<string>(currentChatType);

    const updateChatType = (chatType: string) => {
        recordEvent("chat_type_selected", { chatType });

        if (onChatTypeChange) {
            onChatTypeChange(chatType);
        }

        setSelected(chatType);
    };

    return (
        <Box sx={{ overflow: "visible" }} data-tour="chat-type-selector">
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "left",
                    gap: "5px",
                    paddingBottom: "10px"
                }}
            >
                <Chip
                    onClick={() => updateChatType("chat")}
                    color={selected === "chat" ? "primary" : "neutral"}
                    variant={selected === "chat" ? "solid" : "outlined"}
                >
                    Chat
                </Chip>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Chip
                        onClick={() => updateChatType("find")}
                        color={selected === "find" ? "primary" : "neutral"}
                        variant={selected === "find" ? "solid" : "outlined"}
                    >
                        Search
                    </Chip>
                    <Chip
                        size="sm"
                        variant="soft"
                        sx={{
                            backgroundColor: "#1972aa",
                            color: "white",
                            fontSize: "0.65rem",
                            height: "20px",
                            transform: "translateY(-8px) translateX(-5px)",
                            minHeight: "16px"
                        }}
                    >
                        Free
                    </Chip>
                </Box>
            </Box>
        </Box>
    );
};
