import React, { useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath, DocumentResult } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { CopyButton } from "../CopyButton";
import { removeDuplicates } from "../../util/utils";
import { Box, Button, Sheet, Typography } from "@mui/joy";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";
import { Quote } from "../../pages/pdfChat/types";
import { FeedbackButtons } from "../FeedbackButtons/FeedbackButtons";
import { SourceActionPopup } from "../SourceActionPopup/SourceActionPopup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/joy/styles";

interface Props {
    answer: ChatAppResponse;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (citation: string, document?: DocumentResult) => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    triggerMoreResults?: () => void;
    chatType: string;
    onQuoteClick?: (quote: Quote) => void;
}

interface SelectedSource {
    resultDocument?: DocumentResult;
}

const removeLabels = (text: string) => {
    const labels = ["[frequently_cited]"];
    labels.forEach(label => {
        text = text?.replace(label, "");
    });
    return text;
};

export const Answer: React.FC<Props> = ({
    answer,
    isSelected,
    isStreaming,
    onCitationClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    triggerMoreResults,
    chatType,
    onQuoteClick
}: Props) => {
    const followupQuestions = answer.choices[0]?.context?.followup_questions;
    const quotes: Quote[] = answer.choices[0].context?.quotes?.length > 0 ? JSON.parse(answer.choices[0].context.quotes) : [];
    const messageContent = removeLabels(answer.choices[0].message.content);
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked), [answer]);
    const sanitizedAnswerHtml = `${parsedAnswer.answerHtml}`;

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState<SelectedSource | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleCitationClick = (citation: string, documentName: string) => {
        if (isMobile) {
            // Find the matching resultDocument from answer.documents
            const resultDocument = answer.documents?.find(doc => {
                const cleanName = removeLabels(doc.name).trim();
                const cleanDocumentName = removeLabels(documentName).trim();

                // Extract the case name from the citation path
                const citationMatch = citation.match(/\/content\/(.+)$/);
                const citationName = citationMatch ? citationMatch[1] : citation;

                // Extract the key identifiers (e.g., [2017] UKSC 5)
                const getCaseIdentifier = (text: string) => {
                    const match = text.match(/\[\d{4}\].*?\d+/);
                    return match ? match[0] : "";
                };

                const docIdentifier = getCaseIdentifier(cleanName);
                const citationIdentifier = getCaseIdentifier(citationName);

                return (
                    doc.url === citation ||
                    doc.pdf_url === citation ||
                    // Match by case identifier
                    (docIdentifier && citationIdentifier && docIdentifier === citationIdentifier) ||
                    // Fallback to name matching if identifiers don't match
                    cleanName.includes(cleanDocumentName) ||
                    cleanDocumentName.includes(cleanName)
                );
            });

            // Format the document properly to include pdf_url
            const formattedDocument = resultDocument
                ? {
                      ...resultDocument,
                      url: resultDocument.pdf_url || resultDocument.url
                  }
                : undefined;

            if (formattedDocument) {
                setSelectedSource({ resultDocument: formattedDocument });
                setIsPopupOpen(true);
            } else {
                console.warn("No matching document found for citation:", citation);
            }
        } else {
            // Find the matching document and format it the same way for consistency
            const resultDocument = answer.documents?.find(doc => {
                const cleanName = removeLabels(doc.name).trim();
                const cleanDocumentName = removeLabels(documentName).trim();
                return doc.url === citation || doc.pdf_url === citation || cleanName.includes(cleanDocumentName) || cleanDocumentName.includes(cleanName);
            });

            const formattedDocument = resultDocument
                ? {
                      ...resultDocument,
                      url: resultDocument.pdf_url || resultDocument.url
                  }
                : undefined;

            onCitationClicked(citation, formattedDocument);
        }
    };

    return (
        <Box className={`${styles.answerContainer} ${isSelected && styles.selected}`}>
            <Sheet>
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <div />
                        <Box sx={{ display: "flex", direction: "row" }}>
                            {chatType == "chat" && (
                                <IconButton
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "ClipboardList" }}
                                    title="Show supporting content"
                                    ariaLabel="Show supporting content"
                                    onClick={() => onSupportingContentClicked()}
                                    disabled={!answer.choices[0].context.data_points?.length && !answer.documents?.length}
                                />
                            )}
                            <CopyButton textToCopy={messageContent} />
                        </Box>
                    </Stack>
                </Stack.Item>

                <Stack.Item grow>
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            // @ts-expect-error - index is not in the type
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            quote: ({ node, ...props }) => {
                                // eslint-disable-next-line
                                const index = props.index;
                                const quote = quotes[index];
                                return (
                                    <div
                                        style={{
                                            borderLeft: "4px solid #ccc",
                                            paddingLeft: "16px",
                                            fontStyle: "italic",
                                            color: "#555",
                                            margin: "16px 0"
                                        }}
                                    >
                                        {quote?.text || ""}
                                        {quote?.highlights?.length > 0 && (
                                            <Button
                                                className="supContainer"
                                                onClick={() => (onQuoteClick ? onQuoteClick(quote) : {})}
                                                variant={"outlined"}
                                                size={"sm"}
                                                sx={{ marginLeft: "10px" }}
                                            >
                                                Jump to
                                            </Button>
                                        )}
                                    </div>
                                );
                            },
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            b: ({ node, children, ...props }) => {
                                return (
                                    <div
                                        style={{
                                            borderLeft: "4px solid #ccc",
                                            paddingLeft: "16px",
                                            fontStyle: "italic",
                                            color: "#555",
                                            margin: "16px 0"
                                        }}
                                    >
                                        {children}
                                    </div>
                                );
                            }
                        }}
                        className={styles.answerText}
                    >
                        {sanitizedAnswerHtml}
                    </ReactMarkdown>
                </Stack.Item>

                {!!parsedAnswer.citations.length && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Citations:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                return (
                                    <>
                                        <a key={i} className={styles.citation} title={x} onClick={() => handleCitationClick(path, removeLabels(x))}>
                                            {`${++i}. ${removeLabels(x)}`}
                                        </a>
                                        {x.includes("[frequently_cited]") && (
                                            <div className={styles.frequentlyCitedLabels}>
                                                <FrequentlyCitedLabel />
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}

                {!!answer.documents?.length && !parsedAnswer.citations?.length && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>Documents referenced:</span>
                            {removeDuplicates(answer.documents.slice(0, 7), "name").map((x, i) => {
                                const path = x.url;
                                return (
                                    <a key={i} className={styles.citation} onClick={() => handleCitationClick(path, x.name)}>
                                        {`${++i}. ${x.name}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
                {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                    <Stack.Item>
                        <Stack horizontal wrap className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                            {followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "end",
                        justifyContent: "space-between"
                    }}
                >
                    <FeedbackButtons
                        messageId={answer.choices[0]?.message_id}
                        extraButton={
                            chatType === "chat" ? (
                                <Button size="sm" startDecorator={<LowPriorityIcon />} color="primary" variant="plain" onClick={triggerMoreResults}>
                                    Try with more results
                                </Button>
                            ) : null
                        }
                    />

                    <Typography className={styles.disclaimer} level="body-xs">
                        This is not legal advice.
                    </Typography>
                </Box>
            </Sheet>

            <SourceActionPopup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} resultDocument={selectedSource?.resultDocument} />
        </Box>
    );
};
