import { useAuth } from "@clerk/clerk-react";
import { markDocumentViewed, DocumentResult } from "../../api";

interface DocumentViewHook {
    handleDocumentView: (documentId: string, documentData: Partial<DocumentResult>) => Promise<void>;
}

export const useDocumentView = (): DocumentViewHook => {
    const { getToken } = useAuth();

    const handleDocumentView = async (documentId: string, documentData: Partial<DocumentResult>): Promise<void> => {
        try {
            const token = await getToken();
            if (!token) {
                return;
            }

            const response = await markDocumentViewed(documentId, documentData, token);
            if (!response.ok) {
                await response.text();
            }
        } catch {
            // Silently fail as this is not critical functionality
        }
    };

    return { handleDocumentView };
};
