import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, List, ListItem, ListItemButton, ListItemContent, Sheet, Typography, Chip } from "@mui/joy";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import { listConversations } from "../../api";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./Sidebar.module.css";
import { Link, useNavigate, useSearchParams, useLocation, useParams } from "react-router-dom";
import logo from "../../assets/logo_b.png";
import { useAuth } from "@clerk/clerk-react";
import { UserMenu } from "../UserMenu";
import { format, isToday, isYesterday } from "date-fns";
import { SubscriptionDetail } from "../SubscriptionDetail";
import { Add } from "@mui/icons-material";
import RestoreIcon from "@mui/icons-material/Restore";
import { HistoryMenu } from "../HistoryMenu/HistoryMenu";
import { MobileHistoryMenu } from "../HistoryMenu/MobileHistoryMenu";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { DocMenu } from "../DocMenu/DocMenu";
import TourIcon from "@mui/icons-material/EmojiPeople";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import { useMediaQuery } from "@mui/material";

interface Message {
    answer: string;
    conversation: Conversation;
    created: string;
    id: number;
    question: string;
    updated: string;
    user: string;
}

interface SidebarProps {
    onClose?: () => void;
    sidebarOpen: boolean;
}

export interface ConversationMap {
    [key: string]: Conversation[];
}

export interface Conversation {
    id: string;
    created: string;
    type: "chat" | "pdf" | "project";
    messages: Message[];
    url?: string;
    doc_id?: string;
    project_id?: string;
    favorite?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ onClose, sidebarOpen }) => {
    const { isSignedIn, getToken } = useAuth();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const boxRef = useRef<HTMLElement | null>(null);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [historyMenuOpen, setHistoryMenuOpen] = useState(false);
    const [docMenuOpen, setDocMenuOpen] = useState(false);
    const [mobileHistoryMenuOpen, setMobileHistoryMenuOpen] = useState(false);
    const location = useLocation();
    const isMobile = useMediaQuery("(max-width:844px)");
    const isDesktop = useMediaQuery("(min-width:1401px)");
    const selectedStyle = {
        "&.Mui-selected": {
            backgroundColor: "var(--joy-palette-neutral-200, #e3e8ef)",
            borderRadius: "8px"
        },
        "&:hover": {
            backgroundColor: "var(--joy-palette-neutral-300, #d3dae3)",
            borderRadius: "8px"
        }
    };

    const { countryCode } = useParams();
    const currentCountry = countryCode || "au";

    const getConversations = async (page: number) => {
        if (!hasMoreItems) {
            return;
        }
        setLoading(true);
        const resp = await listConversations(await getToken({ template: "Standard" }), page);
        const data = await resp.json();

        if (data.length === 0) {
            setHasMoreItems(false);
            setLoading(false);
            return;
        }

        if (page === 1) {
            setConversations(data);
        } else {
            const newConversations = data.filter(
                (newConvo: Conversation) => !conversations.some((existingConvo: Conversation) => existingConvo.id === newConvo.id)
            );
            setConversations(prevConversations => [...prevConversations, ...newConversations]);
        }
        setLoading(false);
    };

    const setNewChat = () => {
        searchParams.delete("conversationId");
        setSearchParams(searchParams);
        getConversations(1);
        // Redirect to the current country's base route.
        navigate(`/${currentCountry}`);
        if (onClose) onClose();
    };

    useEffect(() => {
        if (isSignedIn) {
            getConversations(page);
        }
    }, [isSignedIn, page]);

    useEffect(() => {
        const handleScroll = () => {
            const box = boxRef.current;
            if (box && box.scrollHeight - box.scrollTop === box.clientHeight && !loading && hasMoreItems) {
                setPage(prevPage => prevPage + 1);
            }
        };
        const box = boxRef.current;
        if (box) {
            box.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (box) {
                box.removeEventListener("scroll", handleScroll);
            }
        };
    }, [loading, hasMoreItems]);

    // Add new useEffect for mobile view
    useEffect(() => {
        if (isMobile && sidebarOpen) {
            setPage(1);
            setHasMoreItems(true);
            setConversations([]); // Clear existing conversations
            getConversations(1); // Fetch fresh data
        }
    }, [sidebarOpen]);

    const groupedConversations = conversations
        .sort((a, b) => {
            if (a.created && b.created) {
                return new Date(b.created).getTime() - new Date(a.created).getTime();
            } else {
                return 0;
            }
        })
        .reduce((groups: { [key: string]: Conversation[] }, convo) => {
            if (convo.created) {
                let dateObject = new Date(convo.created);
                dateObject = new Date(dateObject.toLocaleString("en-US", { timeZone: "Australia/Sydney" }));
                let formattedDate;
                if (isToday(dateObject)) {
                    formattedDate = "Today";
                } else if (isYesterday(dateObject)) {
                    formattedDate = "Yesterday";
                } else {
                    formattedDate = format(dateObject, "MMMM dd, yyyy");
                }
                if (!groups[formattedDate]) {
                    groups[formattedDate] = [];
                }
                groups[formattedDate].push(convo);
            }
            return groups;
        }, {} as ConversationMap);

    const handleClickAway = (event: MouseEvent | TouchEvent) => {
        // If we're not on desktop and the sidebar is open, handle click away
        if (!isDesktop && onClose) {
            const target = event.target as HTMLElement;
            const isClickInSidebar = target.closest('[class*="Sidebar_container"]');
            const isClickInDocMenu = target.closest('[class*="DocMenu_container"]');
            const isClickInHistoryMenu = target.closest('[class*="HistoryMenu_container"]');
            const isClickInMenuButton = target.closest('[data-testid="menu-button"]');

            if (!isClickInSidebar && !isClickInDocMenu && !isClickInHistoryMenu && !isClickInMenuButton) {
                onClose();
                setDocMenuOpen(false);
                setHistoryMenuOpen(false);
            }
        }
    };

    // Add event listener for new conversations
    useEffect(() => {
        const handleNewConversation = (event: CustomEvent) => {
            const newConvo = event.detail;
            setConversations(prevConversations => [newConvo, ...prevConversations]);
        };

        window.addEventListener("newConversation", handleNewConversation as EventListener);
        return () => {
            window.removeEventListener("newConversation", handleNewConversation as EventListener);
        };
    }, []);

    const handleHistoryMenuClick = () => {
        setHistoryMenuOpen(!historyMenuOpen);
        setDocMenuOpen(false); // Close doc menu if open
    };

    const handleDocMenuClick = () => {
        setDocMenuOpen(!docMenuOpen);
        setHistoryMenuOpen(false); // Close history menu if open
        setMobileHistoryMenuOpen(false); // Close mobile history menu if open
    };

    const handleMobileHistoryClick = () => {
        setMobileHistoryMenuOpen(true);
        setDocMenuOpen(false); // Close doc menu if open
    };

    const handleFavoriteChange = (convoId: string, isFavorite: boolean) => {
        setConversations(prevConversations => prevConversations.map(convo => (convo.id === convoId ? { ...convo, favorite: isFavorite } : convo)));
    };

    const handleTourStart = () => {
        window.dispatchEvent(new CustomEvent("startTour"));
        if (isMobile && onClose) {
            onClose();
        }
    };

    useEffect(() => {
        const handleOpenSidebar = () => {
            if (isMobile) {
                if (onClose) {
                    onClose();
                }
            }
        };

        window.addEventListener("openSidebar", handleOpenSidebar);
        return () => window.removeEventListener("openSidebar", handleOpenSidebar);
    }, [onClose]);

    // Helper functions to determine active state.
    const isSearchActive = () => location.pathname === `/${currentCountry}/casenotes`;
    const isChatActive = () => location.pathname === `/${currentCountry}` && !searchParams.get("conversationId");
    const isProjectsActive = () => location.pathname.startsWith(`/${currentCountry}/view-projects`);

    return (
        <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onClick" touchEvent="onTouchEnd">
            <Sheet
                className="Sidebar_container"
                sx={{
                    position: { xs: "fixed", md: "sticky" },
                    transform: { md: "none" },
                    transition: "transform 0.4s, width 0.4s",
                    height: {
                        xs: "calc(100% - 65px)",
                        sm: "calc(100% - 75px)",
                        md: "100vh"
                    },
                    maxHeight: {
                        xs: "calc(100vh - 60px)",
                        sm: "calc(100vh - 75px)",
                        md: "100vh"
                    },
                    width: "var(--Sidebar-width)",
                    top: {
                        xs: "60px",
                        sm: "65px",
                        md: 0
                    },
                    p: 2,
                    zIndex: 1000,
                    flexShrink: 0,
                    boxSizing: "border-box",
                    display: sidebarOpen ? "flex" : "none",
                    flexDirection: "column",
                    gap: {
                        xs: 0.5,
                        sm: 1,
                        md: 2
                    },
                    borderRight: "1px solid",
                    borderColor: "divider",
                    maxWidth: {
                        xs: "220px",
                        sm: "220px",
                        md: "280px"
                    },
                    minWidth: {
                        xs: "220px",
                        sm: "220px",
                        md: "220px"
                    }
                }}
            >
                <Box sx={{ display: { xs: "none", md: "block" } }} className={styles.logoArea}>
                    <Link to={`/${currentCountry}`} onClick={setNewChat} className={styles.headerTitleContainer}>
                        <img className={styles.headerLogo} src={logo} alt="Logo" />
                    </Link>
                </Box>
                <List
                    sx={{
                        overflow: "hidden",
                        "@media (min-width: 1400px)": {
                            flexGrow: 1,
                            mb: 0
                        },
                        "@media (min-width: 768px) and (max-width: 1399px)": {
                            mb: "-25%"
                        }
                    }}
                    size="sm"
                >
                    <ListItem>
                        <ListItemButton
                            data-tour="new-chat"
                            className={styles.largeListItemButton}
                            onClick={setNewChat}
                            selected={isChatActive()}
                            sx={selectedStyle}
                        >
                            <QuestionAnswerRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-md">New chat</Typography>
                            </ListItemContent>
                            <Add />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            data-tour="search"
                            className={styles.largeListItemButton}
                            onClick={() => navigate(`/${currentCountry}/casenotes`)}
                            selected={isSearchActive()}
                            sx={selectedStyle}
                        >
                            <SearchIcon />
                            <ListItemContent>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography level="title-md">Search</Typography>
                                    <Chip
                                        size="sm"
                                        variant="soft"
                                        sx={{
                                            backgroundColor: "#1972aa",
                                            color: "white",
                                            fontSize: "0.65rem",
                                            height: "20px",
                                            transform: "translateY(-8px)",
                                            padding: "0 6px",
                                            minHeight: "16px"
                                        }}
                                    >
                                        Free
                                    </Chip>
                                </Box>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            data-tour="projects"
                            className={styles.largeListItemButton}
                            onClick={() => navigate(`/${currentCountry}/view-projects`)}
                            selected={isProjectsActive()}
                            sx={selectedStyle}
                        >
                            <FolderIcon />
                            <ListItemContent>
                                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                                    <Typography level="title-md">Projects</Typography>
                                    <Chip
                                        size="sm"
                                        variant="soft"
                                        sx={{
                                            backgroundColor: "#1972aa",
                                            color: "white",
                                            fontSize: "0.65rem",
                                            height: "20px",
                                            transform: "translateY(-8px)",
                                            padding: "0 6px",
                                            minHeight: "16px"
                                        }}
                                    >
                                        New
                                    </Chip>
                                </Box>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    {/* Desktop and Tablet History Menu */}
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                        <ListItem>
                            <ListItemButton
                                data-tour="history"
                                className={styles.largeListItemButton}
                                onClick={handleHistoryMenuClick}
                                selected={historyMenuOpen}
                                sx={selectedStyle}
                            >
                                <RestoreIcon />
                                <ListItemContent>
                                    <Typography level="title-md">Chats</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <HistoryMenu
                            open={historyMenuOpen}
                            onClose={() => setHistoryMenuOpen(false)}
                            groupedConversations={groupedConversations}
                            onFavoriteChange={handleFavoriteChange}
                        />
                    </Box>
                    {/* Mobile History Menu */}
                    <Box sx={{ display: { xs: "block", sm: "none" } }}>
                        <ListItem>
                            <ListItemButton className={styles.largeListItemButton} onClick={handleMobileHistoryClick}>
                                <RestoreIcon />
                                <ListItemContent>
                                    <Typography level="title-md">Chats</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <MobileHistoryMenu
                            open={mobileHistoryMenuOpen}
                            onClose={() => setMobileHistoryMenuOpen(false)}
                            groupedConversations={groupedConversations}
                            onSidebarClose={onClose}
                            onFavoriteChange={handleFavoriteChange}
                        />
                    </Box>
                    <ListItem>
                        <ListItemButton
                            data-tour="documents"
                            className={styles.largeListItemButton}
                            onClick={handleDocMenuClick}
                            selected={docMenuOpen}
                            sx={selectedStyle}
                        >
                            <DescriptionIcon />
                            <ListItemContent>
                                <Typography level="title-md">Documents</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>

                <Divider
                    sx={{
                        "@media (min-width: 1400px)": { my: 1 },
                        "@media (min-width: 768px) and (max-width: 1399px)": { my: 0, transform: "translateY(-25%)" }
                    }}
                />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        "@media (min-width: 1400px)": { flexGrow: 0, gap: 1 },
                        "@media (min-width: 768px) and (max-width: 1399px)": { flexGrow: 0, gap: 0.25, transform: "translateY(-25%)" }
                    }}
                >
                    <List size="sm" sx={{ mb: { xs: 0, sm: 0.5, md: 1 } }}>
                        <ListItem>
                            <ListItemButton className={styles.largeListItemButton} onClick={handleTourStart}>
                                <TourIcon />
                                <ListItemContent>
                                    <Typography level="title-md">Tour</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    {isSignedIn && (
                        <div data-tour="subscription">
                            <SubscriptionDetail />
                        </div>
                    )}
                    <UserMenu />
                </Box>
                <DocMenu
                    open={docMenuOpen}
                    onClose={() => {
                        setDocMenuOpen(false);
                        if (!isDesktop && onClose) {
                            onClose();
                        }
                    }}
                />
            </Sheet>
        </ClickAwayListener>
    );
};
