import React, { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import Stack from "@mui/joy/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Textarea from "@mui/joy/Textarea";
import { styled } from "@mui/joy/styles";
import IconButton from "@mui/joy/IconButton";

interface CreateProjectModalProps {
    open: boolean;
    onClose: () => void;
    onCreateProject: (name: string, description: string) => void;
}

const LargeModalDialog = styled(ModalDialog)(({ theme }) => ({
    maxWidth: "600px",
    width: "40%",
    [theme.breakpoints.up("md")]: {
        width: "80%"
    }
}));

const CloseButton = styled(IconButton)({
    position: "absolute",
    right: "10px",
    top: "10px"
});

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({ open, onClose, onCreateProject }) => {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onCreateProject(name, description);
        handleClose();
    };

    const handleClose = () => {
        onClose();
        setName("");
        setDescription("");
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <LargeModalDialog>
                <CloseButton onClick={handleClose}>
                    <CloseIcon />
                </CloseButton>
                <DialogTitle>{"Create new project"}</DialogTitle>
                <DialogContent>Fill in the information of the project.</DialogContent>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input autoFocus required value={name} onChange={e => setName(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea minRows={3} value={description} onChange={e => setDescription(e.target.value)} />
                        </FormControl>
                        <Button type="submit">Submit</Button>
                    </Stack>
                </form>
            </LargeModalDialog>
        </Modal>
    );
};

export default CreateProjectModal;
