import { Box, Button, Sheet, Table, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import styles from "./ResultsTable.module.css";
import { Download } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { DateOptions, getDate } from "../../util/utils";
import { DocumentResult } from "../../api";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";
import { ChatPDFButton } from "../ChatPDFButton";
import React from "react";
import { SummaryViewer } from "../SummaryViewer";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ViewOriginalButton } from "../ViewOriginalButton/ViewOriginalButton";

const dateOptions: DateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

export interface Row {
    name: string;
    jurisdiction: string;
    date: string;
    type: string;
    url: string;
    extract: string;
    category: string | undefined;
    summary1?: string;
    summary_version?: number;
    most_cited?: boolean;
    pdf_url?: string;
    id: string;
    doc_id: string;
    blurb?: string;
}

export const ResultsTable = ({ documents, totalResults = 0 }: { documents: DocumentResult[]; totalResults?: number }): JSX.Element => {
    let rows: Row[] = documents.map(document => {
        return {
            name: document.name,
            jurisdiction: document.jurisdiction,
            date: getDate(document, dateOptions),
            type: document.doc_type,
            url: document.url,
            extract: document.extract,
            category: document.category,
            summary1: document.summary1,
            summary_version: document.summary_version,
            most_cited: document.most_cited,
            id: document.doc_id,
            doc_id: document.doc_id,
            pdf_url: document.pdf_url,
            blurb: document.blurb
        };
    });

    useEffect(() => {
        rows = documents.map(document => {
            return {
                name: document.name,
                jurisdiction: document.jurisdiction,
                date: getDate(document, dateOptions),
                type: document.doc_type,
                url: document.url,
                extract: document.extract,
                category: document.category,
                summary1: document.summary1,
                summary_version: document.summary_version,
                most_cited: document.most_cited,
                id: document.doc_id,
                doc_id: document.doc_id,
                pdf_url: document.pdf_url,
                blurb: document.blurb
            };
        });
    }, [documents]);

    return (
        <div className={styles.answerContainer}>
            {totalResults > 0 && (
                <Box className={styles.resultsCount}>
                    <Typography level="body-xs">Approximately {totalResults.toLocaleString()} results</Typography>
                </Box>
            )}
            <Sheet className={styles.tableWrapper}>
                <Table size={"lg"}>
                    <tbody>
                        {rows.map(row => (
                            <Row key={row.name} row={row} />
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </div>
    );
};

function isValidJSON(input: string): boolean {
    try {
        JSON.parse(input);
        return true;
    } catch {
        return false;
    }
}

export const Row = ({ row }: { row: Row }): JSX.Element => {
    const [showFullBlurb, setShowFullBlurb] = useState(false);
    const isMobile = useMediaQuery("(max-width:844px)");
    const navigate = useNavigate();

    const isPDF = (url: string) => {
        return url.toLowerCase().endsWith(".pdf");
    };

    const truncateText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + "...";
    };

    const handleTitleClick = (e: React.MouseEvent) => {
        if (isPDF(row.pdf_url || row.url)) {
            e.preventDefault();
            navigate("/pdf-chat", {
                state: {
                    pdfUrl: row.pdf_url || row.url,
                    documentId: row.doc_id,
                    documentName: row.name
                }
            });
        }
    };

    return (
        <>
            <tr key={row.name}>
                <td>
                    <Sheet>
                        <Typography
                            component={"a"}
                            href={row.url}
                            level={"body-md"}
                            onClick={handleTitleClick}
                            sx={{
                                cursor: isPDF(row.pdf_url || row.url) ? "pointer" : "default",
                                textDecoration: "none",
                                color: "inherit",
                                "&:hover": {
                                    textDecoration: isPDF(row.pdf_url || row.url) ? "underline" : "none"
                                }
                            }}
                        >
                            {row.name}
                        </Typography>
                        <Box className={styles.metadata}>
                            {row.date && <Typography level={"body-xs"}>{format(new Date(row.date), "d MMMM yyyy")}</Typography>}
                            {row.date && row.jurisdiction && <Typography level={"body-xs"}>&nbsp;·&nbsp;</Typography>}
                            {row.jurisdiction && <Typography level={"body-xs"}>{row.jurisdiction}</Typography>}
                        </Box>
                        {row.most_cited && <FrequentlyCitedLabel />}
                    </Sheet>
                    <Sheet sx={{ p: 1, pl: 3, pt: 2, pb: 2 }}>
                        <Typography level="body-sm" component="div">
                            {row.extract}
                        </Typography>
                        {row.blurb && (
                            <Typography level="body-sm" sx={{ mt: 2, color: "neutral.500" }}>
                                {showFullBlurb ? row.blurb : truncateText(row.blurb, isMobile ? 50 : 200)}
                                {row.blurb.length > (isMobile ? 50 : 200) && (
                                    <Button size="sm" variant="plain" color="primary" onClick={() => setShowFullBlurb(!showFullBlurb)} sx={{ ml: 1 }}>
                                        {showFullBlurb ? "Show less" : "Show more"}
                                    </Button>
                                )}
                            </Typography>
                        )}
                    </Sheet>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: isMobile ? 1 : 3
                        }}
                    >
                        {row.summary1 && isValidJSON(row.summary1) && <SummaryViewer document={row} mobileView={isMobile} />}
                        {isPDF(row.pdf_url || row.url) && (
                            <>
                                <ChatPDFButton document={row} />
                                <Button
                                    startDecorator={isMobile ? undefined : <Download />}
                                    variant={"plain"}
                                    size={"md"}
                                    component="a"
                                    href={row.pdf_url || row.url}
                                    download
                                    target="_blank"
                                    sx={{ textDecoration: "none" }}
                                    rel="noopener noreferrer"
                                >
                                    {isMobile ? <Download /> : "Download"}
                                </Button>
                            </>
                        )}
                        <ViewOriginalButton document={row} mobileView={isMobile} />
                    </Box>
                </td>
            </tr>
        </>
    );
};
