import React, { useRef, useEffect } from "react";
import { Box, Button, Typography } from "@mui/joy";
import { QuestionInput } from "../QuestionInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserChatMessage } from "../UserChatMessage";
import { Answer, AnswerError, AnswerLoading } from "../Answer";
import { Quote } from "../../pages/pdfChat/types";
import { ChatAppResponse, ChatAppResponseOrError } from "../../api";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ChatViewProps<T> {
    SideComponent: React.FC<T>;
    sideComponentProps: T;
    onClose: () => void;
    handleSendQuestion: (question: string) => void;
    answers: [string, ChatAppResponseOrError][];
    isLoading: boolean;
    error?: any;
    lastQuestionRef: React.MutableRefObject<string>;
    fullWidth?: boolean;
    handleQuoteClick?: (quote: Quote) => void;
}

export const ChatView = <T extends object>({
    SideComponent,
    sideComponentProps,
    onClose,
    handleSendQuestion,
    answers,
    isLoading,
    error,
    lastQuestionRef,
    handleQuoteClick
}: ChatViewProps<T>) => {
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const isLargeScreen = useMediaQuery("(min-width:1200px)");
    const isMobile = useMediaQuery("(max-width:844px)");

    useEffect(() => {
        if (isLoading) {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        } else {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [answers, isLoading]);

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                height: isLargeScreen ? "100vh" : "calc(100vh - 80px)",
                mt: isLargeScreen ? 0 : "80px"
            }}
        >
            <Box
                sx={{
                    width: isLargeScreen ? "50%" : "100%",
                    maxWidth: isLargeScreen ? "50%" : "100%",
                    borderRight: isLargeScreen ? "1px solid #ccc" : "none",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: isMobile ? "4px" : "8px", pt: "16px", px: "16px" }}>
                    <Box sx={{ display: "flex", width: "100%", gap: 1, mb: isMobile ? 1 : 2 }}>
                        <Button onClick={onClose} startDecorator={<ArrowBackIcon />} size="sm" variant={"outlined"}>
                            Back
                        </Button>
                        <Typography level="h4">Chat</Typography>
                    </Box>

                    <QuestionInput placeholder="Type your question here" onSend={handleSendQuestion} clearOnSend />
                </Box>
                <Box
                    sx={{
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        height: "100%",
                        msOverflowStyle: "none",
                        "&::-webkit-scrollbar": {
                            display: "none"
                        }
                    }}
                >
                    {" "}
                    {answers.map((answer, index) => (
                        <Box sx={{ padding: "16px" }} key={index}>
                            <UserChatMessage message={answer[0]} />
                            <Answer
                                isStreaming={false}
                                key={index}
                                answer={answer[1] as ChatAppResponse}
                                chatType="pdf"
                                isSelected={false}
                                onCitationClicked={() => {}}
                                onSupportingContentClicked={() => {}}
                                onFollowupQuestionClicked={handleSendQuestion}
                                showFollowupQuestions={true}
                                onQuoteClick={handleQuoteClick}
                            />
                        </Box>
                    ))}
                    {isLoading && (
                        <>
                            <UserChatMessage message={lastQuestionRef.current} />
                            <Box sx={{ maxWidth: "500px" }}>
                                <AnswerLoading gifsLoaded={true} />
                            </Box>
                        </>
                    )}
                    {error && (
                        <>
                            <UserChatMessage message={lastQuestionRef.current} />
                            <div>
                                <AnswerError error={error.toString()} onRetry={() => handleSendQuestion(lastQuestionRef.current)} />
                            </div>
                        </>
                    )}
                    <div ref={chatMessageStreamEnd} />
                </Box>
            </Box>
            {isLargeScreen && (
                <Box sx={{ width: "50%", height: "100%", overflow: "hidden" }}>
                    <SideComponent {...sideComponentProps} />
                </Box>
            )}
        </Box>
    );
};
