import React from "react";
import { ListItem, Typography, IconButton, Box } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteFile } from "../../../api";
import { ChatPDFButton } from "../../ChatPDFButton";
import { useAuth } from "@clerk/clerk-react";
import { FileInfo } from "../types";
import { isPDF } from "../../../util/utils";
import DescriptionIcon from "@mui/icons-material/Description";

interface FileItemProps {
    file: FileInfo;
    onFileDelete: (filename: string) => void;
    onFileClick: (fileInfo: FileInfo) => void;
}

const FileItem: React.FC<FileItemProps> = ({ file, onFileDelete, onFileClick }) => {
    const { getToken } = useAuth();

    const handleFileDelete = async () => {
        try {
            await deleteFile(file.id, await getToken({ template: "Standard" }));
            onFileDelete(file.id);
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    return (
        <React.Fragment>
            <ListItem sx={{ padding: "4px 0", marginBottom: 0 }}>
                {" "}
                <Box className="file-item" sx={{ padding: "8px", width: "100%" }}>
                    {" "}
                    <Box className="file-name-container" onClick={() => onFileClick(file)}>
                        <DescriptionIcon />
                        <Typography noWrap className="filename">
                            {file.name}
                        </Typography>
                    </Box>
                    <Box className="file-actions">
                        {(isPDF(file.name) || isPDF(file.url)) && (
                            <ChatPDFButton
                                document={{
                                    name: file.name,
                                    url: file.url,
                                    doc_id: file.az_id
                                }}
                            />
                        )}
                        <IconButton size="sm" color="danger" onClick={handleFileDelete}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            </ListItem>
        </React.Fragment>
    );
};
export default FileItem;
