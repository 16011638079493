import React from "react";
import { Button } from "@mui/joy";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

interface ChatPDFButtonProps {
    document: DocumentMinimumFIelds;
    variantOverride?: "plain" | "soft" | "outlined";
    onComplete?: () => void;
    isMenuButton?: boolean;
}

interface DocumentMinimumFIelds {
    doc_id?: string;
    pdf_url?: string;
    url?: string;
    // eslint-disable-next-line
    [key: string]: any;
}

export const ChatPDFButton: React.FC<ChatPDFButtonProps> = ({ document, variantOverride, onComplete, isMenuButton = false }) => {
    const isMobile = useMediaQuery("(max-width:844px)");
    const navigate = useNavigate();
    const { countryCode } = useParams();
    const handleClick = () => {
        navigate(`/${countryCode}/pdf-chat`, {
            state: {
                pdfUrl: document.pdf_url || document.url,
                documentId: document.doc_id,
                documentName: document.name
            }
        });
        if (onComplete) {
            onComplete();
        }
    };

    const showFullButton = !isMobile || isMenuButton;

    return (
        <Button
            startDecorator={showFullButton ? <ChatOutlinedIcon /> : undefined}
            size="md"
            variant={variantOverride ? variantOverride : "plain"}
            onClick={handleClick}
        >
            {showFullButton ? "Chat PDF" : <ChatOutlinedIcon />}
        </Button>
    );
};
