import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Button, Tooltip, Link, Divider, Skeleton } from "@mui/joy";
import ChatIcon from "@mui/icons-material/Chat";
import DropZone from "../DropZone/DropZone";
import FileList from "../FileList/FileList";
import { FileInfo, Project } from "../types";
import "./FolderContent.css";
import { getProject, SavedDocument } from "../../../api";
import { useAuth } from "@clerk/clerk-react";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import { useSnackbar } from "../../../contexts/useSnackbar";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";

const ProjectLoadingShell = () => {
    return (
        <Box sx={{ p: 2, gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pb: 5 }}>
                <Skeleton variant="rectangular" width="10%" height={40} />
                <Skeleton variant="rectangular" width="10%" height={40} />
            </Box>
            <Skeleton variant="text" width="100%" height={40} />
            <Skeleton variant="text" width="100%" height={40} />
            <Skeleton variant="text" width="100%" height={40} />
            <Skeleton variant="text" width="100%" height={40} />
            <Skeleton variant="text" width="100%" height={40} />
        </Box>
    );
};

const ProjectView = () => {
    const [loading, setLoading] = useState(false);
    const { projectId } = useParams<{ projectId: string }>();
    const { getToken } = useAuth();
    const [project, setProject] = useState<Project | undefined>(undefined);
    const [files, setFiles] = useState<FileInfo[]>([]);
    const [savedDocuments, setSavedDocuments] = useState<FileInfo[]>([]);
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const isMobile = useMediaQuery("(max-width:844px)");

    const FILE_LIST_HEIGHT = "400px"; // Height to show approximately 6 files

    const fetchFolderDetails = async () => {
        if (!projectId) {
            return;
        }
        try {
            setLoading(true);
            const data = await getProject(projectId, await getToken({ template: "Standard" }));
            if (data.saved_documents) {
                setSavedDocuments(convertDocumentsToFiles(data.saved_documents));
            }
            setProject(data);
            if (data.files) setFiles(data.files);
        } catch (error) {
            showSnackbar({ message: "Failed to fetch project details", variant: "danger" });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFolderDetails();
    }, [projectId]);

    if (!projectId) {
        return <div>Project not found</div>;
    }

    const isProjectEmpty = files.length === 0 && savedDocuments.length === 0;

    const convertDocumentsToFiles = (documents: SavedDocument[]): FileInfo[] => {
        return documents.map(document => ({
            id: document.doc_id,
            name: document.data?.name,
            url: document.data?.pdf_url || document.data?.url,
            az_id: document.doc_id
        }));
    };

    const handleFileUpload = (file: FileInfo) => {
        setFiles(prevFiles => [...prevFiles, file]);
    };

    const handleFileDelete = (id: string) => {
        setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
    };

    const handleTalkToProject = (): void => {
        const countryCode = window.location.pathname.split("/")[1];
        navigate(`/${countryCode}/projchat/${projectId}`);
    };

    const handleFindDocs = (): void => {
        navigate(`/${window.location.pathname.split("/")[1]}/casenotes`);
    };

    return (
        <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", p: 2, gap: 2 }}>
            <Box sx={{ mx: -1 }}>
                <Breadcrumbs aria-label="breadcrumbs" size={"sm"}>
                    <Link key={"projects"} color="neutral" href="/view-projects">
                        Projects
                    </Link>
                    <Typography>{project?.name}</Typography>
                </Breadcrumbs>
            </Box>
            {!loading ? (
                <>
                    <Box className="folder-header" sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Button variant="outlined" color="primary" startDecorator={<SearchIcon />} onClick={handleFindDocs}>
                                Find Docs
                            </Button>
                            <Tooltip title={isProjectEmpty ? "Project empty" : ""} placement="top">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startDecorator={<ChatIcon />}
                                    onClick={handleTalkToProject}
                                    disabled={isProjectEmpty}
                                    className="talk-to-project-button"
                                >
                                    Talk to Project
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        className="folder-content-body"
                        sx={{
                            flexGrow: 1,
                            width: "100%",
                            gap: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: isMobile ? "calc(100vh - 200px)" : "auto",
                            position: "relative"
                        }}
                    >
                        <Box
                            sx={{
                                height: isMobile ? "100%" : FILE_LIST_HEIGHT,
                                overflowY: "auto",
                                mb: isMobile ? "90px" : 2,
                                "&::-webkit-scrollbar": {
                                    width: "8px"
                                },
                                "&::-webkit-scrollbar-track": {
                                    background: "#f1f1f1",
                                    borderRadius: "4px"
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#888",
                                    borderRadius: "4px"
                                },
                                "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#555"
                                }
                            }}
                        >
                            {savedDocuments.length > 0 && (
                                <>
                                    <Divider>Saved Documents</Divider>
                                    <FileList existingFiles={savedDocuments} folderId={projectId} onFileDelete={handleFileDelete} onFileClick={() => {}} />
                                </>
                            )}
                            {files.length > 0 && (
                                <>
                                    <Divider>Uploads</Divider>
                                    <FileList existingFiles={files} folderId={projectId} onFileDelete={handleFileDelete} onFileClick={() => {}} />
                                </>
                            )}
                        </Box>
                        <Box
                            sx={{
                                position: isMobile ? "fixed" : "static",
                                bottom: 0,
                                left: 0,
                                right: 0,
                                p: 2,
                                bgcolor: "background.body",
                                borderTop: isMobile ? "1px solid" : "none",
                                borderColor: "divider",
                                width: "100%",
                                zIndex: 1
                            }}
                        >
                            <DropZone onFileUpload={handleFileUpload} projectId={projectId} existingFiles={files} />
                        </Box>
                    </Box>
                </>
            ) : (
                <ProjectLoadingShell />
            )}
        </Box>
    );
};

export default ProjectView;
