import React from "react";
import { Box, List } from "@mui/joy";
import FileItem from "../FileItem/FileItem";
import { FileInfo } from "../types";

interface FileListComponentProps {
    // Renamed from FileListProps to FileListComponentProps
    existingFiles: FileInfo[];
    folderId: string | undefined;
    onFileDelete: (filename: string) => void;
    onFileClick: (fileInfo: FileInfo) => void;
}

const FileList: React.FC<FileListComponentProps> = ({ existingFiles, onFileDelete, onFileClick }) => {
    return (
        <Box className="file-list" sx={{ width: "100%" }}>
            <List sx={{ width: "100%", padding: 0 }}>
                {existingFiles.map(fileInfo => (
                    <FileItem key={fileInfo.name} file={fileInfo} onFileDelete={onFileDelete} onFileClick={onFileClick} />
                ))}
            </List>
        </Box>
    );
};

export default FileList;
