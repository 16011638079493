import React from "react";
import { Modal, Typography, Button, ModalDialog } from "@mui/joy";

interface BillingIssueModalProps {
    open: boolean;
    url: string;
}

const BillingIssueModal: React.FC<BillingIssueModalProps> = ({ open, url }) => {
    return (
        <Modal open={open} onClose={() => {}} disableEscapeKeyDown>
            <ModalDialog layout={"center"}>
                <Typography id="modal-modal-title" component="h2">
                    Billing Issue
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Your last payment to Courtaid failed, please update your payment details or cancel your plan to continue using the service.
                </Typography>
                <a href={url} style={{ textDecoration: "none" }}>
                    <Button color="primary" sx={{ mt: 2 }}>
                        Update Payment Details
                    </Button>
                </a>
            </ModalDialog>
        </Modal>
    );
};

export default BillingIssueModal;
