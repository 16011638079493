import { Box, Button, DialogTitle, Divider, Modal, ModalClose, ModalDialog, Table, Textarea, Typography } from "@mui/joy";
import React, { useContext } from "react";
import { recordEvent } from "../../util/utils";
import { CopyButton } from "../CopyButton";
import RedeemIcon from "@mui/icons-material/Redeem";
import { UserContext } from "../../contexts/UserContext";
import { useMediaQuery } from "@mui/material";

export const ShareAndCopyLink = ({ inviteCode }: { inviteCode: string | undefined }) => {
    const referralLink = `https://app.courtaid.ai/?invite_code=${inviteCode}`;
    return (
        <div>
            <Typography level={"body-md"}>Your Referral Link:</Typography>
            {inviteCode && (
                <Box sx={{ display: "flex", direction: "row", alignItems: "center" }}>
                    <Textarea variant="soft" value={referralLink} sx={{ width: "75%", maxWidth: "500px" }} readOnly={true} />
                    <CopyButton textToCopy={referralLink} />
                </Box>
            )}
        </div>
    );
};

export const ReferralModal = () => {
    const [open, setOpen] = React.useState(false);
    const { user } = useContext(UserContext);
    const isMobile = useMediaQuery("(max-width:844px)");

    const setModalOpen = (open: boolean) => {
        if (open) {
            recordEvent("Referral modal opened", {});
        }
        setOpen(open);
    };

    return (
        <>
            <Button variant="solid" color="primary" onClick={() => setModalOpen(true)} startDecorator={<RedeemIcon />}>
                Earn Queries
            </Button>
            <Modal open={open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ModalDialog layout={isMobile ? "fullscreen" : "center"}>
                    <DialogTitle>
                        <Typography level={"h3"} variant={"plain"} color={"primary"}>
                            {" "}
                            Invite a friend and get free queries
                        </Typography>
                    </DialogTitle>
                    <Typography level={"body-md"}>Share your referral code with a friend, and you both get 10 free queries. </Typography>
                    <ShareAndCopyLink inviteCode={user?.referral_code} />
                    <Divider />
                    <Typography level={"body-md"}>Your Referral History</Typography>
                    {(user ? user.referrals.length : 0) > 0 ? (
                        <Table>
                            <th>Referral Email</th>
                            <th>Number of bonus searches</th>
                            <th>Expiry</th>
                            {user?.referrals.map(referral => (
                                <tr>
                                    <td>{referral.referred.email}</td>
                                    <td>10</td>
                                    <td>4 October 2024</td>
                                </tr>
                            ))}
                        </Table>
                    ) : (
                        <Typography level={"body-xs"}>No referrals yet</Typography>
                    )}
                    <ModalClose onClick={() => setOpen(false)} />
                </ModalDialog>
            </Modal>
        </>
    );
};
