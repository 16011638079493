import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import { Project } from "../types";

interface ProjectCardProps {
    project: Project;
    onDelete: (id: string) => void;
    onChat: (id: string) => void;
    onClick: (id: string) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, onDelete, onClick }) => {
    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        onDelete(project.id);
    };

    const truncateDescription = (description: string, maxLength: number) => {
        if (description.length <= maxLength) return description;
        return `${description.substring(0, maxLength)}...`;
    };

    return (
        <Card
            variant="outlined"
            sx={{
                width: 280, // Fixed width
                height: 225,
                cursor: "pointer",
                "&:hover": { boxShadow: "md", borderColor: "primary.main" },
                display: "flex",
                flexDirection: "column"
            }}
            onClick={() => onClick(project.id)}
        >
            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Typography level="h2" fontSize="md" sx={{ mb: 2 }}>
                    {project.name}
                </Typography>
                <Typography level="body-sm" sx={{ mb: 2, flexGrow: 1 }}>
                    {truncateDescription(project.description || "", 100)}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                    {project.created && <Typography level="body-xs">Created: {new Date(project.created).toLocaleDateString()}</Typography>}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box></Box>
                    <Button size="sm" variant="plain" color="danger" startDecorator={<DeleteIcon />} onClick={handleDelete}>
                        Delete
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ProjectCard;
