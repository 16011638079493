import React, { createContext, useContext, useState, useEffect } from "react";
import { COUNTRIES, Country } from "../util/constants";
import { UserContext } from "./UserContext";
import { updateUser } from "../api";
import { useAuth } from "@clerk/clerk-react";
import { useParams, useNavigate } from "react-router-dom";

interface CountryContextType {
    country: Country | null;
    setCountryByCode: (code: string | undefined) => void;
    setCountryByName: (name: string | undefined) => void;
}

export const CountryContext = createContext<CountryContextType>({
    country: null,
    setCountryByCode: () => console.warn("no country provider"),
    setCountryByName: () => console.warn("no country provider")
});

interface Props {
    children: React.ReactNode;
}

export const CountryProvider: React.FC<Props> = ({ children }) => {
    const { countryCode } = useParams();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { getToken } = useAuth();

    // Determine the initial country from either the user's default or the URL parameter
    const initialCountry = COUNTRIES.find(c => c.name === user?.default_country) || COUNTRIES.find(c => c.code === countryCode) || null;

    const [country, setCountry] = useState<Country | null>(initialCountry);

    // If there is no countryCode in the URL, redirect based on user settings or a fallback default.
    useEffect(() => {
        if (!countryCode) {
            const defaultCode = (user?.default_country && COUNTRIES.find(c => c.name === user.default_country)?.code) || "au";
            navigate(`/${defaultCode}`, { replace: true });
        }
    }, [countryCode, user, navigate]);

    const setCountryByCode = async (code: string | undefined) => {
        if (!code) {
            setCountry(null);
            return;
        }
        const newCountry = COUNTRIES.find(c => c.code === code) || null;
        setCountry(newCountry);
        const token = await getToken({ template: "Standard" });
        updateUser(token, { default_country: newCountry?.name });
        // If the URL's countryCode is different, update the URL.
        if (newCountry && countryCode !== newCountry.code) {
            navigate(`/${newCountry.code}`, { replace: true });
        }
    };

    const setCountryByName = async (name: string | undefined) => {
        if (!name) {
            setCountry(null);
            return;
        }
        const newCountry = COUNTRIES.find(c => c.name === name) || null;
        setCountry(newCountry);
        const token = await getToken({ template: "Standard" });
        updateUser(token, { default_country: name });
        // If the URL's countryCode is different, update the URL.
        if (newCountry && countryCode !== newCountry.code) {
            navigate(`/${newCountry.code}`, { replace: true });
        }
    };

    return <CountryContext.Provider value={{ country, setCountryByCode, setCountryByName }}>{children}</CountryContext.Provider>;
};
