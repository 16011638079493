import { createContext, useState, ReactNode } from "react";
import { Snackbar, Typography } from "@mui/joy";
import ErrorIcon from "@mui/icons-material/Error";

interface ErrorContextType {
    showSnackbar: (options: SnackbarOptions) => void;
}

const SnackbarContext = createContext<ErrorContextType | undefined>(undefined);

interface ErrorProviderProps {
    children: ReactNode;
}

interface SnackbarOptions {
    message: string;
    variant: "success" | "warning" | "danger";
}

const SnackbarProvider = ({ children }: ErrorProviderProps) => {
    const [snackbarOptions, setSnackbar] = useState<SnackbarOptions | null>(null);

    const showSnackbar = (options: SnackbarOptions) => {
        setSnackbar(options);
    };

    const clearSnackbar = () => {
        setSnackbar(null);
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(snackbarOptions)}
                autoHideDuration={4000}
                onClose={clearSnackbar}
                color={snackbarOptions?.variant || "success"}
                variant="solid"
                startDecorator={<ErrorIcon />}
            >
                {/*@ts-expect-error - color prop i dunno*/}
                <Typography color="white" level="title-sm">
                    {snackbarOptions?.variant === "success"
                        ? "Success"
                        : snackbarOptions?.variant === "warning"
                          ? "Warning"
                          : snackbarOptions?.variant === "danger"
                            ? "Error"
                            : "Info"}
                </Typography>
                {snackbarOptions?.message}
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

export { SnackbarProvider, SnackbarContext };
