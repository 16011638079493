import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/joy";
import { PDFViewer, PDFViewerHandle } from "../../components/PDFViewer/PDFViewer";
import { QuestionInput } from "../../components/QuestionInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { UserChatMessage } from "../../components/UserChatMessage";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { Highlight, Quote } from "./types";
import { ChatAppResponse } from "../../api";
import styles from "./PDFChatView.module.css";
import loading_animation_1 from "../../assets/loading_animation_1.gif";
import loading_animation_2 from "../../assets/loading_animation_2.gif";
import loading_animation_3 from "../../assets/loading_animation_3.gif";
import { animations } from "../../util/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useMediaQuery } from "@mui/material";

interface PDFChatViewProps {
    pdfUrl: string;
    documentId: string;
    documentName: string;
    onClose: () => void;
    handleSendQuestion: (question: string) => void;
    answers: [string, ChatAppResponse][];
    isLoading: boolean;
    // eslint-disable-next-line
    error: any;
    quotes: Quote[];
    lastQuestionRef: React.MutableRefObject<string>;
}

export const PDFChatView: React.FC<PDFChatViewProps> = ({
    pdfUrl,
    documentName,
    onClose,
    handleSendQuestion,
    answers,
    isLoading,
    error,
    quotes,
    lastQuestionRef
}) => {
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const pdfViewerRef = useRef<PDFViewerHandle | null>(null);
    const mobilePdfViewerRef = useRef<PDFViewerHandle | null>(null);
    const hiddenPdfViewerRef = useRef<PDFViewerHandle | null>(null);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [gifsLoaded, setGifsLoaded] = useState(false);
    const [splitPosition, setSplitPosition] = useState(50); // Default 50% split
    const isDraggingRef = useRef(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery("(max-width:844px)");

    useEffect(() => {
        if (window.innerWidth <= 768 && hiddenPdfViewerRef.current) {
            // Force the hidden viewer to load the PDF immediately
            const hiddenViewer = document.querySelector('div[style*="display: none"] .rpv-core__viewer');
            if (hiddenViewer) {
                hiddenViewer.dispatchEvent(new Event("resize"));
            }
        }
    }, []);

    // Add GIF preloading
    useEffect(() => {
        const preloadGifs = async () => {
            const urls = [loading_animation_1, loading_animation_2, loading_animation_3];
            const loadedGifs = await Promise.all(
                urls.map(async url => {
                    const response = await fetch(url);
                    return URL.createObjectURL(await response.blob());
                })
            );
            animations.push(...loadedGifs);
            setGifsLoaded(true);
        };
        preloadGifs();
    }, []);

    const handleQuoteClick = (quote: Quote) => {
        const firstHighlight: Highlight = quote.highlights[0];
        if (firstHighlight) {
            const area = {
                pageIndex: firstHighlight.pageIndex,
                height: firstHighlight.height,
                left: firstHighlight.left,
                top: Math.max(firstHighlight.top - 15, 0),
                width: firstHighlight.width
            };

            // Check if we're on mobile (window width <= 768px)
            if (window.innerWidth <= 768) {
                // First ensure the hidden viewer has jumped to the right spot
                if (hiddenPdfViewerRef.current) {
                    hiddenPdfViewerRef.current.jumpToHighlightArea(area);
                }
                // Then show the modal
                setShowPdfModal(true);
                // Use a longer timeout to ensure PDF is fully loaded
                setTimeout(() => {
                    if (mobilePdfViewerRef.current) {
                        mobilePdfViewerRef.current.jumpToHighlightArea(area);
                    }
                }, 300); // Increased from 100ms to 300ms
            } else if (pdfViewerRef.current) {
                pdfViewerRef.current.jumpToHighlightArea(area);
            }
        }
    };

    useEffect(() => {
        if (isLoading) {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        } else {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [answers, isLoading]);

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        isDraggingRef.current = true;
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDraggingRef.current || !containerRef.current) return;

        const containerRect = containerRef.current.getBoundingClientRect();
        const newPosition = ((e.clientX - containerRect.left) / containerRect.width) * 100;

        // Limit the resizing range (e.g., between 30% and 80%)
        const limitedPosition = Math.min(Math.max(newPosition, 30), 80);
        setSplitPosition(limitedPosition);
    };

    const handleMouseUp = () => {
        isDraggingRef.current = false;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    return (
        <>
            <div className={styles.container} ref={containerRef}>
                <Box className={styles.chatSection} sx={{ width: `${splitPosition}%`, gap: "16px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: isMobile ? "4px" : "8px", pt: "16px", px: "16px" }}>
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", mb: isMobile ? 1 : 2 }}>
                            <Button onClick={onClose} startDecorator={<ArrowBackIcon />} size="sm" variant="outlined">
                                Back
                            </Button>
                            {window.innerWidth <= 1200 && (
                                <Button onClick={() => setShowPdfModal(true)} size="sm" variant="outlined" startDecorator={<VisibilityIcon />}>
                                    View PDF
                                </Button>
                            )}
                        </Box>
                        <Typography level={isMobile ? "body-md" : "h4"}>Chat with {documentName}</Typography>
                        <QuestionInput placeholder="Type your question here" onSend={handleSendQuestion} clearOnSend />
                    </Box>

                    <Box
                        sx={{
                            overflowY: "auto",
                            scrollbarWidth: "none",
                            height: "100%",
                            msOverflowStyle: "none",
                            "&::-webkit-scrollbar": {
                                display: "none"
                            }
                        }}
                    >
                        {answers.map((answer, index) => (
                            <Box sx={{ paddingX: "16px" }} key={index}>
                                <UserChatMessage message={answer[0]} />
                                <Answer
                                    isStreaming={false}
                                    key={index}
                                    answer={answer[1]}
                                    chatType="pdf"
                                    isSelected={false}
                                    onCitationClicked={() => void 0}
                                    onSupportingContentClicked={() => void 0}
                                    onFollowupQuestionClicked={handleSendQuestion}
                                    showFollowupQuestions={true}
                                    onQuoteClick={handleQuoteClick}
                                />
                            </Box>
                        ))}
                        {isLoading && (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} />
                                <Box sx={{ maxWidth: "500px" }}>
                                    <AnswerLoading gifsLoaded={gifsLoaded} />
                                </Box>
                            </>
                        )}
                        {error && (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} />
                                <div>
                                    <AnswerError error={error.toString()} onRetry={() => handleSendQuestion(lastQuestionRef.current)} />
                                </div>
                            </>
                        )}
                    </Box>
                    <div ref={chatMessageStreamEnd} />
                </Box>
                <div className={styles.divider} onMouseDown={handleMouseDown} />
                <div className={styles.pdfSection} style={{ width: `${100 - splitPosition}%` }}>
                    <PDFViewer fileUrl={pdfUrl} ref={pdfViewerRef} currentQuery={lastQuestionRef.current} quotes={quotes} />
                </div>
                <div style={{ display: "none" }}>
                    <PDFViewer fileUrl={pdfUrl} ref={hiddenPdfViewerRef} currentQuery={lastQuestionRef.current} quotes={quotes} />
                </div>
            </div>

            <div className={`${styles.pdfModal} ${showPdfModal ? styles.open : ""}`}>
                <Box
                    sx={{
                        position: "sticky",
                        top: "65px",
                        zIndex: 2,
                        backgroundColor: "background.surface",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2,
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        marginTop: "65px"
                    }}
                >
                    <Button onClick={onClose} startDecorator={<ArrowBackIcon />} size="sm" variant="outlined">
                        Back
                    </Button>
                    <Button onClick={() => setShowPdfModal(false)} variant="outlined" color="neutral" startDecorator={<CloseIcon />}>
                        Close
                    </Button>
                </Box>
                <div className={styles.pdfModalContent}>
                    <PDFViewer fileUrl={pdfUrl} ref={mobilePdfViewerRef} currentQuery={lastQuestionRef.current} quotes={quotes} />
                </div>
            </div>
        </>
    );
};
