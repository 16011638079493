export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export interface ChatAppRequestOverrides {
    retrieval_mode?: RetrievalMode;
    semantic_ranker?: boolean;
    semantic_captions?: boolean;
    exclude_category?: string;
    skip?: number;
    top?: number;
    prompt_template?: string;
    prompt_template_prefix?: string;
    prompt_template_suffix?: string;
    suggest_followup_questions?: boolean;
    use_oid_security_filter?: boolean;
    use_groups_security_filter?: boolean;
    project_id?: number;
    filters?: FilterRequest;
}

export interface ResponseMessage {
    content: string;
    role: string;
}

export interface ResponseContext {
    thoughts: string | null;
    data_points: string[];
    followup_questions: string[] | null;
    quotes: string;
}

export interface ResponseChoice {
    index: number;
    message: ResponseMessage;
    context: ResponseContext;
    message_id: number;
    session_state: Record<string, unknown>;
}

export interface ChatAppResponseOrError {
    conversation_id: string;
    choices?: ResponseChoice[];
    documents: DocumentResult[];
    error?: string;
}

export interface ChatAppResponse {
    conversation_id: string;
    choices: ResponseChoice[];
    documents: DocumentResult[];
}

export interface ChatAppRequestContext {
    overrides?: ChatAppRequestOverrides;
    chat_type?: string;
}

export interface FilterRequest {
    [key: string]: string[] | null;
}

export interface ChatAppRequest {
    search?: string;
    conversation_id?: string;
    messages: ResponseMessage[];
    context?: ChatAppRequestContext;
    stream?: boolean;
    // TODO: delete?
    // eslint-disable-next-line
    session_state: any;
}

export interface SearchRequest {
    search: string;
    context: ChatAppRequestContext;
}

export interface JudgementCaseNote {
    case_title_citation: string;
    judgement_date: string;
    court: string;
    judge: string;
    parties?: {
        plaintiff?: string;
        defendant?: string;
    };
    issue: string;
    law: string;
    analysis: string;
    significance: string;
    conclusion: string;
}

export interface LegislationSummary {
    title_commencement: string;
    purpose_objectives: string;
    key_provisions: string;
    scope_application: string;
    rights_obligations: string;
    enforcement_penalties: string;
    exemptions_defenses: string;
    related_legislation_case_law: string;
    impact_implications: string;
}

export interface DocumentResult {
    before: string;
    cases_cited: string;
    category: string;
    court: string;
    date: string;
    sort_date: string;
    decision: string;
    decision_date: string;
    date_of_decision: string;
    doc_id: string;
    doc_type: "judgement";
    extract: string;
    id: string;
    jurisdiction: string;
    name: string;
    text: string;
    url: string;
    summary1?: string;
    summary_version?: number;
    categories?: string[];
    blurb?: string;
    most_cited?: boolean;
    pdf_url?: string;
    highlights?: string[];
}

export interface DocumentData {
    name: string;
    url: string;
    pdf_url?: string;
    summary1?: string;
    // Add any other fields that might be in the data JSON
}

export interface SavedDocument {
    id: string;
    doc_id: string;
    data: DocumentData;
    favourite: string | null; // ISO date string or null
    viewed: string | null; // ISO date string or null
    updated: string | null; // ISO date string or null
}

export interface DocumentsResponse {
    documents: SavedDocument[];
}

export interface User {
    // ... existing fields ...
    tour: string | null; // Will be a datetime string when completed, null otherwise
}
